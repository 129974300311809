import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Label } from 'components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CustomerEdit } from './components';
import { toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { userService } from 'service';
import { loadingActions } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { permissionsService } from 'service/permissions.service';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > * + *': {
      marginLeft: 0
    }
  },
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CustomerInfo = props => {
  const { customer, className, history, ...rest } = props;
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  const session = useSelector(state => state.session);

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    dispatch(loadingActions.setLoader(true));
    permissionsService.getAllRoles().then(
      r => {
        setRoles(r);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  }, []);

  const updateRoles = event => {
    dispatch(loadingActions.setLoader(true));
    const newRole = event.target.value == 0 ? null : event.target.value;
    customer.roles = newRole;
    userService.userEdit(customer).then(
      r => {
        dispatch(loadingActions.setLoader(false));
        customer.roles = newRole;
        toast('Success');
      },
      err => {
        dispatch(loadingActions.setLoader(false));
        toast('Error occured please contact Admin');
      }
    );
  };

  const deleteUser = e => {
    dispatch(loadingActions.setLoader(true));
    userService.userDelete(customer?.id).then(
      e => {
        toast('Устгагдлаа');
        history.push('/management/customers');
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };
  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Хэрэглэгчийн мэдээлэл" />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{customer.email}</TableCell>
              </TableRow>
              <TableRow selected>
                <TableCell>Утас</TableCell>
                <TableCell>{customer.mobile}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Овог</TableCell>
                <TableCell>{customer.firstname}</TableCell>
              </TableRow>
              <TableRow selected>
                <TableCell>Нэр</TableCell>
                <TableCell>{customer.lastname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Баталгаажилт</TableCell>
                <TableCell>
                  {customer.verified}
                  <div>
                    <Label
                      color={
                        customer.verified
                          ? colors.green[600]
                          : colors.orange[600]
                      }>
                      {customer.verified ? 'verified' : 'not verified'}
                    </Label>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow selected>
                <TableCell>Төрөл</TableCell>
                <TableCell>
                  {customer.isCreator}
                  <div>
                    <Label
                      color={
                        customer.isCreator
                          ? colors.green[600]
                          : colors.orange[600]
                      }>
                      {customer.isCreator ? 'Түрээслүүлэгч' : 'Түрээслэгч'}
                    </Label>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        {(permissions.includes('USER_ACTIONS') ||
          permissions.includes('ALL')) && (
          <>
            <CardActions className={classes.actions}>
              <Button onClick={handleEditOpen}>
                <EditIcon className={classes.buttonIcon} />
                Засах
              </Button>
              <Button onClick={deleteUser}>
                <DeleteIcon className={classes.buttonIcon} />
                Устгах
              </Button>
            </CardActions>
            <CustomerEdit
              customer={customer}
              onClose={handleEditClose}
              open={openEdit}
            />
          </>
        )}
      </Card>
      {(permissions.includes('USER_ROLE_CHANGE') ||
        permissions.includes('ALL')) && (
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Админ эрх" />
          <Divider />
          <CardContent>
            <div className={classes.mainActions}>
              <TextField
                fullWidth
                name="option"
                onChange={updateRoles}
                select
                SelectProps={{ native: true }}
                value={customer?.roles?.id}
                variant="outlined">
                <option key={`roles-selection-customer-null`} value={0}>
                  Хэрэглэгч
                </option>
                {roles.map(option => (
                  <option
                    key={`roles-selection-${option.id}`}
                    value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default CustomerInfo;
