import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from 'history';
import 'quill/dist/quill.snow.css';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/index.scss';
import { ScrollReset } from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/prismjs';
import './mixins/validate';
import routes from './routes';
import theme from './theme';

const history = createBrowserHistory();

const App = props => {
  return (
    <LoadingOverlay active={props.isActive} spinner text="Loading...">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            {renderRoutes(routes)}
            <ToastContainer />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </LoadingOverlay>
  );
};

const mapStateToProps = state => {
  return {
    isActive: state.loading?.isLoading
  };
};
export default connect(mapStateToProps)(App);
