import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import loadingActionReducer from './loadingReducer';


const rootReducer = combineReducers({
  session: sessionReducer,
  loading: loadingActionReducer
});

export default rootReducer;
