import { colors, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import React from 'react';
import { Redirect } from 'react-router-dom';
import RolePermissions from './Permissions';
import RoleNotifications from './Notifications';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: 0
  },
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  rootModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    padding: theme.spacing(3),
    width: '100%'
  },
  actionsModal: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const RoleDetail = props => {
  const classes = useStyles();

  const { match, history } = props;
  const { id, tab } = match.params;

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'permissions', label: 'Эрх' },
    { value: 'notifications', label: 'Мэдэгдэл' }
  ];

  if (!tab) {
    return <Redirect to={`/roles/detail/${id}/permissions`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Page className={classes.root} title="Админий төрлийн эрх засах">
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'permissions' && <RolePermissions id={id} history={history} />}
        {tab === 'notifications' && <RoleNotifications id={id} />}
      </div>
    </Page>
  );
};

export default RoleDetail;
