import { sendRequest } from './middleware';

const getAllRoles = async () => {
  let config = {
    method: 'get',
    url: '/api/permissions/getRoles',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.data) return resp.data;
  else return [];
};

const getPermissionsByRole = async roleId => {
  let config = {
    method: 'get',
    url: `/api/permissions/getRolePermissions/${roleId}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.data) return resp;
  else return { data: [] };
};

const getRoleNotificationConfig = async id => {
  let config = {
    method: 'get',
    url: `/api/notificationconfig/getByRole/${id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.data) return resp;
  else return { data: [] };
};

const updatePermissions = async (roleId, body) => {
  let config = {
    method: 'post',
    url: `/api/permissions/updatePermissions/${roleId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp.success;
  else return false;
};

const updateNotifConfigs = async (roleId, body) => {
  let config = {
    method: 'post',
    url: `/api/notificationconfig/updateConfigs/${roleId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp.success;
  else return false;
};

const createRole = async name => {
  let config = {
    method: 'get',
    url: '/api/permissions/createRole/' + name,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.data) return resp;
  else return { data: [] };
};

const getAllByuser = async (userId, skip, take, sort = [], filter = []) => {
  let config = {
    method: 'post',
    url: '/api/log/user/' + userId,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      skip: skip,
      take: take,
      sort: sort,
      filter: filter
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

export const permissionsService = {
  getAllByuser,
  getAllRoles,
  createRole,
  getRoleNotificationConfig,
  getPermissionsByRole,
  updatePermissions,
  updateNotifConfigs
};
