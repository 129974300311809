import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Label } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { postsService } from 'service';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  inner: {},
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  }
}));

const options = [
  { value: 'NEW', name: 'Веб-д ороогүй' },
  { value: 'Active', name: 'Веб-д орсон' },
  { value: 'Rejected', name: 'Зөвшөөрөгдөөгүй' }
];

const paymentStatusColors = {
  NEW: colors.orange[600],
  Active: colors.green[600],
  Rejected: colors.red[600]
};

const Results = props => {
  const { className, filter, ...rest } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orders, setOrders] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const formatPrice = price => {
    return new Intl.NumberFormat().format(Number(price).toFixed(2)) + '₮';
  };

  useEffect(() => {
    let mounted = true;

    postsService
      .getAll(
        page * rowsPerPage,
        rowsPerPage,
        { field: 'id', dir: 'DESC' },
        filter
      )
      .then(response => {
        if (mounted && response.success) {
          setOrders(response.data);
        }
      });

    postsService.getPostCount().then(response => {
      setTotalRecord(response.total);
    });
  }, [rowsPerPage, page, filter]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalRecord} Records found. Page {page + 1} of{' '}
        {Math.ceil(totalRecord / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Гарчиг</TableCell>
                    <TableCell>Оруулсан</TableCell>
                    <TableCell>Үнэ</TableCell>
                    <TableCell>email</TableCell>
                    <TableCell>Төлөв</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map(order => (
                    <TableRow key={order.id}>
                      <TableCell>{order.id}</TableCell>

                      <TableCell>{order.title}</TableCell>
                      <TableCell>
                        {moment(order.postDate).format('YYYY-MM-DD | hh:mm')}
                      </TableCell>
                      <TableCell>{formatPrice(order.price)}</TableCell>
                      <TableCell>{order.user?.email}</TableCell>
                      <TableCell>
                        <Label
                          color={paymentStatusColors[order.status]}
                          variant="outlined">
                          {options.find(x => x.value == order.status)?.name}
                        </Label>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={'/management/orders/' + order.id}
                          variant="outlined">
                          Харах
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalRecord}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired,
  filter: PropTypes.array
};

Results.defaultProps = {
  orders: [],
  filter: []
};

export default Results;
