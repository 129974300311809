import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { GenericMoreButton, Label } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { postsService } from '../../../../service';
import { loadingActions } from 'actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {},
  author: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  tags: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const options = [
  { value: 'NEW', name: 'Веб-д ороогүй' },
  { value: 'Active', name: 'Веб-д орсон' },
  { value: 'Rejected', name: 'Зөвшөөрөгдөөгүй' }
];


const paymentStatusColors = {
  NEW: colors.orange[600],
  Active: colors.green[600],
  Rejected: colors.red[600]
};


const LatestProjects = props => {
  const dispatch = useDispatch();


  const { className, ...rest } = props;

  const classes = useStyles();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchOrders = () => {
      dispatch(loadingActions.setLoader(true));
      postsService.getAll(0, 5, { field: 'id', dir: 'DESC' }).then(
        response => {
          dispatch(loadingActions.setLoader(false));
          if (mounted && response.success) {
            setOrders(response.data);
          }
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
    };

    fetchOrders();

    return () => {
      mounted = false;
    };
  }, []);

  const formatPrice = price => {
    return new Intl.NumberFormat().format(Number(price).toFixed(2)) + '₮';
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Сүүлийн зарнууд" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>Гарчиг</TableCell>
                  <TableCell>Оруулсан</TableCell>
                  <TableCell>Үнэ</TableCell>
                  <TableCell>Хэрэглэгч</TableCell>
                  <TableCell>Төлөв</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.slice(0, 5).map(order => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>

                    <TableCell>{order.title}</TableCell>
                    <TableCell>
                      {moment(order.postDate).format('YYYY-MM-DD | hh:mm')}
                    </TableCell>
                    <TableCell>{formatPrice(order.price)}</TableCell>
                    <TableCell>{order.user?.email}</TableCell>
                    <TableCell>
                      <Label
                        color={paymentStatusColors[order.status]}
                        variant="outlined">
                        {options.find(x => x.value == order.status)?.name}
                      </Label>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={'/management/orders/' + order.id}
                        variant="outlined">
                        Харах
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/management/orders"
          variant="text">
          Бүгд
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestProjects.propTypes = {
  className: PropTypes.string
};

export default LatestProjects;
