import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  colors
} from '@material-ui/core';
import { Label } from 'components';

import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { blogService } from 'service';
import moment from 'moment';
import { loadingActions } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const BlogList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, pg) => {
    setPage(pg);
    dispatch(loadingActions.setLoader(true));
    blogService.getAll(pg * rowsPerPage, rowsPerPage).then(
      r => {
        setCustomers(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const handleChangeRowsPerPage = event => {
    const value = event.target.value;
    setRowsPerPage(value);

    dispatch(loadingActions.setLoader(true));
    blogService.getAll(page * value, value).then(
      r => {
        setCustomers(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchCustomers = () => {
    dispatch(loadingActions.setLoader(true));
    blogService.getAll(page * rowsPerPage, rowsPerPage).then(
      r => {
        setCustomers(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const onDelete = id => {
    dispatch(loadingActions.setLoader(true));
    blogService.deleteItem(id).then(
      r => {
        fetchCustomers();
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  return (
    <Page className={classes.root} title="Хэрэглэгч">
      {customers && (
        <div className={classes.results}>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {customers.length} Records found. Page {page + 1} of{' '}
            {Math.ceil(customers.length / rowsPerPage)}
          </Typography>
          <Card>
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Гарчиг</TableCell>
                        <TableCell>Төлөв</TableCell>
                        <TableCell>Оруулсан</TableCell>
                        <TableCell>Оруулсан он сар</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.map(item => (
                        <TableRow hover key={item.id}>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>
                            <Label
                              color={
                                item.status === 'PUBLISHED'
                                  ? colors.green[600]
                                  : colors.orange[600]
                              }>
                              {item.status === 'PUBLISHED'
                                ? 'Идэвхитэй'
                                : 'Идэвхигүй'}
                            </Label>
                          </TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              component={RouterLink}
                              size="small"
                              to={'/management/customers/' + item.user?.id}
                              variant="outlined">
                              {item.user?.email}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {moment(item.created_at).format(
                              'DD MMM YYYY | hh:mm'
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary"
                              component={RouterLink}
                              size="small"
                              to={'/blog/edit/' + item.id}
                              variant="outlined">
                              Засах
                            </Button>
                            <Button
                              color="primary"
                              size="small"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                onDelete(item.id);
                              }}
                              variant="outlined">
                              Устгах
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </CardActions>
          </Card>
        </div>
      )}
    </Page>
  );
};

export default BlogList;
