import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  TextField,
  Typography,
  colors,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import { Page } from 'components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { permissionsService } from 'service/permissions.service';
import { useForm, Controller } from 'react-hook-form';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import useRouter from 'utils/useRouter';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: 0
  },
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  rootModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    padding: theme.spacing(3),
    width: '100%'
  },
  actionsModal: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const PERMISSIONS = [
  { groupName: 'Бүгд', permissions: [{ name: 'Бүгд', action: 'ALL' }] },
  {
    groupName: 'Зар',
    permissions: [
      { name: 'Шинэ зар нэмэгдэх', action: 'POST_CREATE' },
      {
        name: 'Түрээслэх хүсэлтийн төлбөр төлөх',
        action: 'PAYMENT_REQUEST_ADMIN'
      }
    ]
  },
  {
    groupName: 'Цуцлах',
    permissions: [
      {
        name: 'Түрээслэгч цуцлах хүсэлт гаргах',
        action: 'USER_CANCELED_REQUEST_ADMIN'
      },
      {
        name: 'Түрээслүүлэгч цуцлах хүсэлт гаргах',
        action: 'CREATOR_CANCEL_REQUEST_ADMIN'
      }
    ]
  },
  {
    groupName: 'Баталгаажуулалт',
    permissions: [
      {
        name: 'Иргэний үнэмлэх, данс баталгаажуулах хүсэлт',
        action: 'CREATOR_VERIFICATION_REQ'
      }
    ]
  },

  {
    groupName: 'Холбогдох хүсэлт',
    permissions: [{ name: 'Шинэ холбогдох хүсэлт', action: 'CONTACT_REQ' }]
  }
];

const RoleNotifications = props => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { id, match, history } = props;
  const [openModal, setOpenModal] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [allCheck, setAllCheck] = useState(false);
  const { register, handleSubmit, reset, control, getValues } = useForm();

  useEffect(() => {
    fetchRolePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRolePermission = () => {
    dispatch(loadingActions.setLoader(true));
    permissionsService.getRoleNotificationConfig(id).then(
      r => {
        const permission = r.data;
        const aa = {};
        for (let pe of permission) {
          aa[pe.key] = true;
          if (pe.key == 'ALL') {
            setAllCheck(true);
          }
        }
        reset(aa);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const onSubmit = e => {
    dispatch(loadingActions.setLoader(true));
    permissionsService.updateNotifConfigs(id, e).then(
      r => {
        if (r == true) {
          toast('Амжилттай');
        } else {
          toast('Алдаа гарлаа');
        }
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          action={
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: 10, marginRight: 10 }}>
              <SaveIcon style={{ paddingRight: 5 }} />
              Хадгалах
            </Button>
          }
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
        <CardContent className={classes.content}>
          <Grid style={{ width: '100%' }} container spacing={1}>
            {PERMISSIONS.map((permission, idx) => (
              <Grid
                item
                sm={6}
                style={{ padding: 20 }}
                key={`permission-group-${idx}`}>
                <div
                  style={{
                    borderRadius: 5,
                    borderWidth: 1,
                    alignItems: 'center',
                    borderColor: 'rgb(63 63 68 / 15%)',
                    borderStyle: 'solid',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    padding: 30
                  }}>
                  <Typography
                    variant="overline"
                    style={{
                      position: 'absolute',
                      top: -7,
                      left: 10,
                      backgroundColor: '#FFFFFF',
                      fontSize: '14px'
                    }}>
                    {permission.groupName}
                  </Typography>
                  <Grid container style={{ width: '100%' }}>
                    {permission.permissions.map((perm, idx1) => {
                      return (
                        <Grid
                          item
                          md={6}
                          xs={6}
                          key={`checkbox-perm-${perm}-${idx1}`}
                          style={{
                            display: 'flex',
                            wordWrap: 'break-word',
                            paddingTop: 5,
                            paddingBottom: 5
                          }}>
                          <FormControlLabel
                            control={
                              <Controller
                                name={perm.action}
                                control={control}
                                defaultValue={false}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    color="primary"
                                    checked={
                                      allCheck == true ? true : props.value
                                    }
                                    onChange={e => {
                                      if (perm.action == 'ALL') {
                                        setAllCheck(e.target.checked);
                                      }
                                      props.onChange(e.target.checked);
                                    }}
                                  />
                                )}
                              />
                            }
                            label={perm.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Form>
    </Card>
  );
};

export default RoleNotifications;
