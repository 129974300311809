const CommandList = {
  LOG_IN: 'Нэвтрэх',
  LOG_IN_GMAIL: 'Нэвтрэх - GMAIL',
  LOG_IN_FB: 'Нэвтрэх - FB',
  LOG_OUT: 'Гарах',
  FORGOT_PW: 'Нууц үгээ мартсан и-мейл',
  FORGOT_PW_RESET: 'Нүүц үгээ мартаад сэргээх',
  REGISTER: 'Бүргүүлэх',
  REGISTER_FB: 'Бүртгүүлэх FB',
  REGISTER_GMAIL: 'Бүртгүүлэх GMail',
  VERIFY_MOBILE: 'Утасны дугаараа баталгаажуулах',
  VERIFY_EMAIL: 'email Баталгаажуулах',
  CHANGE_PWD: 'Нууц үг солих',
  DELETE_POST: 'Зар устгах',
  EXPORT_XLSX: 'XLSX експорт',
  UPDATE_CONFIG: 'Тохиргоо өөрчлөх',
  CREATE_BLOG: 'Блог бичин',
  UPDATE_BLOG: 'Блог засах',
  DELETE_BLOG: 'Блог устгах',
  CREATE_CANCELATION: 'Буцаалтын дүрэм үүсгэх',
  DELETE_CANCELATION: 'Буцаалтын дүрэм устгах',
  UPDATE_CANCELATION: 'Буцаалтын дүрэм засах',
  CREATE_CATEGORY: 'Ангилал нэмэх',
  DELETE_CATEGORY: 'Ангилал устгах',
  UPDATE_CATEGORY: 'Ангилал засах',
  CREATE_POST: 'Зар нэмэх',
  UPDATE_POST: 'Зар засах',
  UPDATE_POST_ADMIN: 'Админ зар засах',
  ACCEPT_RENT_REQUEST: 'Түрээслэх хүсэлт зөвшөөрөх',
  PROMO_REQUEST: 'Онцгой болгох',
  RENT_REQUEST: 'Түрээслэх хүсэлт',
  RENT_CANCEL_USER: 'Хэрэглэгч түрээслэх хүсэлт цуцлах',
  RENT_CANCEL_CREATOR: 'Түрээслүүлэгч түрээслэх хүсэлт цуцлах',
  CREATOR_CANCEL_REQUEST_ACCEPTED:
    'Түрээслэгчийн түрээслэх хүсэлт цуцлахыг зөвшөөрөх',
  CREATOR_CANCEL_REQUEST_DECLINED:
    'Түрээслэгчийн түрээслэх хүсэлт цуцлахыг татгалзах'
};
export default CommandList;
