import { colors, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import React from 'react';
import { General } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Categories = props => {
  const classes = useStyles();

  return (
    <Page className={classes.root}>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <General />
      </div>
    </Page>
  );
};

export default Categories;
