import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import React from 'react';
import {
  General, Header
} from './components';


const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Settings = props => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Header />
      <General />
    </Page>
  );
};

export default Settings;
