import { sendRequest } from './middleware';

const getAll = async (skip, take, sort = [], filter = []) => {
  let config = {
    method: 'post',
    url: '/api/posts/getPostsAdmin',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      skip: skip,
      take: take,
      sortData: sort,
      filterData: filter
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const getPostCount = async () => {
  let config = {
    method: 'get',
    url: '/api/posts/total',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  return resp;
};

const postDetail = async id => {
  let config = {
    method: 'get',
    url: '/api/posts/' + id,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const postEdit = async postObject => {
  let config = {
    method: 'put',
    url: '/api/posts/adminUpdate',
    headers: {
      'Content-Type': 'application/json'
    },
    data: postObject
  };
  const resp = await sendRequest(config);
  if (resp?.msg == 'success') return true;
  else return false;
};

const exportData = async () => {
  let config = {
    method: 'get',
    url: '/api/admin/excel/export/posts',
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'blob' }
  };
  const resp = await sendRequest(config);
  return resp;
};

export const postsService = {
  getAll,
  postDetail,
  postEdit,
  getPostCount,
  exportData
};
