import { sendRequest } from './middleware';

const getAll = async (skip, take, sort = [], filter = []) => {
  let config = {
    method: 'post',
    url: '/api/log/paginate',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      skip: skip,
      take: take,
      sortData: sort,
      filterData: filter
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const getAllTransactions = async (startDate, endDate, type = 'ALL') => {
  let config = {
    method: 'post',
    url: '/api/transaction-log/getAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      startDate,
      endDate,
      type
    }
  };
  const resp = await sendRequest(config);
  if (resp?.data) return resp.data;
  else return [];
};

const getAllByuser = async (userId, skip, take, sort = [], filter = []) => {
  let config = {
    method: 'post',
    url: '/api/log/user/' + userId,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      skip: skip,
      take: take,
      sortData: sort,
      filterData: filter
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

export const logService = {
  getAllByuser,
  getAll,
  getAllTransactions
};
