import { sendRequest } from './middleware';



const getAll = async (skip, take, sort = [], filter = []) => {

    let config = {
        method: 'get',
        url: '/api/admin/category',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);

    return resp;
};


const categoryDetail = async (id) => {
    let config = {
        method: 'get',
        url: '/api/admin/category/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    return resp;
};
const categoryEdit = async (object) => {
    let config = {
        method: 'put',
        url: '/api/admin/category',
        headers: {
            'Content-Type': 'application/json',
        },
        data: object
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return resp;
    else return [];
};

const deleteCategory = async (object) => {
    let config = {
        method: 'DELETE',
        url: '/api/admin/category/' + object.id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: object
    };
    const resp = await sendRequest(config);
    return resp;
};


const addCategory = async (object) => {
    let config = {
        method: 'POST',
        url: '/api/admin/category',
        headers: {
            'Content-Type': 'application/json',
        },
        data: object
    };
    const resp = await sendRequest(config);
    return resp;
};

export const categoryService = {
    getAll,
    categoryDetail,
    categoryEdit,
    addCategory,
    deleteCategory
};