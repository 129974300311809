import { sendRequest } from './middleware';



const getAll = async (skip, take, sort = [], filter = []) => {

    let config = {
        method: 'post',
        url: '/api/rentreq/cancel/getAll',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            skip: skip,
            take: take,
            sortData: sort,
            filterData: filter
        }
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return resp;
    else return [];
};

const getPostCount = async () => {
    let config = {
        method: 'get',
        url: '/api/posts/total',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const resp = await sendRequest(config);
    return resp;
}

const detail = async (id) => {
    let config = {
        method: 'get',
        url: '/api/rentreq/cancel/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return resp;
    else return [];
};


const update = async (postObject) => {
    let config = {
        method: 'put',
        url: '/api/rentreq/cancel/update',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return true;
    else return false;
};

const changeStatus = async (postObject) => {
    let config = {
        method: 'post',
        url: '/api/rentreq/changeStatusCreatorCancel',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return true;
    else return false;
};


export const rentCancelService = {
    getAll,
    detail,
    update,
    getPostCount,
    changeStatus

};