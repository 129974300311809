import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { contentService } from 'service';
import './style.css';
import Resizer from 'react-image-file-resizer';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const ContentDetail = props => {
  const { category, figure, className, refresh, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedFileBg, setSelectedFileBg] = useState(null);

  const [contentDetail, setContentDetail] = useState({
    id: null,
    key: '',
    name: '',
    value: '',
    title: '',
    image: '',
    group: ''
  });

  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    dispatch(loadingActions.setLoader(true));
    contentService
      .update({ ...contentDetail, title: data.title, value: data.value })
      .then(
        r => {
          if (r) {
            setContentDetail({
              ...contentDetail,
              title: data.title,
              value: data.value
            });
            refresh();
            dispatch(loadingActions.setLoader(false));
          }
        },
        err => {
          toast('Алдаа гарлаа');
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  const onFileChangeBg = event => {
    setSelectedFileBg(event.target.files[0]);
  };

  const onFileUploadBg = () => {
    if (selectedFileBg) {
      dispatch(loadingActions.setLoader(true));
      Resizer.imageFileResizer(
        selectedFileBg,
        50,
        50,
        'PNG',
        100,
        0,
        uri => {
          const formData = new FormData();

          formData.append('image', uri, uri.name);
          axios.post('/api/upload', formData).then(
            r => {
              if (r.data) {
                contentService.update({ ...contentDetail, image: r.data }).then(
                  r => {
                    contentService.detail(contentDetail.id).then(
                      r => {
                        if (r && r.data) {
                          setContentDetail(r.data);
                        }
                        dispatch(loadingActions.setLoader(false));
                      },
                      err => {
                        toast('Алдаа гарлаа');
                        dispatch(loadingActions.setLoader(false));
                      }
                    );
                  },
                  err => {
                    toast('Алдаа гарлаа');
                    dispatch(loadingActions.setLoader(false));
                  }
                );
              } else {
                toast('Алдаа гарлаа');
                dispatch(loadingActions.setLoader(false));
              }
            },
            err => {
              toast('Алдаа гарлаа');
              dispatch(loadingActions.setLoader(false));
            }
          );
        },
        'file',
        50,
        50
      );
    }
  };

  useEffect(() => {
    if (category?.id) {
      setContentDetail(category);
    }
  }, [category]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          {figure === 1 && (
            <Grid item md={6} xs={6}>
              <div class="section infographics-3">
                <div className="acr-infographic-item">
                  <i>
                    <img
                      alt="img"
                      src={
                        contentDetail?.image
                          ? '/files/' + contentDetail?.image
                          : 'https://cdn-icons-png.flaticon.com/512/1828/1828970.png'
                      }
                      style={{ width: '40px' }}
                    />
                  </i>
                  <div className="acr-infographic-item-body">
                    <h5>{contentDetail?.title}</h5>
                    <p>{contentDetail?.value}</p>
                  </div>
                </div>
              </div>
            </Grid>
          )}

          {figure === 2 && (
            <Grid item md={6} xs={6}>
              <div class="section infographics-2">
                <div className="acr-infographic-item">
                  <i>
                    <img
                      alt="img"
                      src={
                        contentDetail?.image
                          ? '/files/' + contentDetail?.image
                          : 'https://cdn-icons-png.flaticon.com/512/1828/1828970.png'
                      }
                      style={{ width: '40px' }}
                    />
                  </i>
                  <div className="acr-infographic-item-body">
                    <h5>{contentDetail?.title}</h5>
                    <p>{contentDetail?.value}</p>
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <div className="row">
              <div className="col-md-12 form-group">
                <label>Гарчиг</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Гарчиг"
                  name="title"
                  defaultValue={contentDetail?.title || ''}
                  {...register('title', {
                    required: true
                  })}
                />
              </div>
              <div className="col-md-12 form-group">
                <label>Тайлбар</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Тайлбар"
                  name="value"
                  rows={5}
                  defaultValue={contentDetail?.value || ''}
                  {...register('value', {
                    required: true
                  })}
                />
              </div>
              <div className="col-md-12 form-group">
                <label>Icon</label>
                <br />
                <input type="file" onChange={onFileChangeBg} />
                <Button
                  onClick={onFileUploadBg}
                  variant="contained"
                  color="secondary">
                  Оруулах
                </Button>
              </div>
            </div>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            // onClick={save}
          >
            Хадгалах
          </Button>
        </CardActions>
      </Form>
    </Card>
  );
};

ContentDetail.propTypes = {
  className: PropTypes.string
};

export default ContentDetail;
