import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/styles';
import { Page, SearchBar } from 'components';
import React, { useState } from 'react';
import { postsService } from 'service';
import { Results } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const OrderManagementList = () => {
  const classes = useStyles();

  const [filter, setFilter] = useState([]);

  const onSearch = text => {
    if (text) {
      const aa = filter;
      aa.push({ field: 'title', op: 'LIKE', qry: text });
      setFilter(aa);
    } else setFilter([]);
  };

  const onFilter = val => {
    const filt = [];
    for (let fil in val) {
      filt.push({ field: fil, op: val[fil].op, qry: val[fil].value });
    }
    setFilter(filt);
  };

  const onClear = () => {
    setFilter([]);
  };

  const handleDownload = async () => {
    const resp = await postsService.exportData();

    const outputFilename = `${Date.now()}.xlsx`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([resp]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const filterObject = [
    {
      type: 'text',
      field: 'title',
      name: 'Гарчиг',
      op: 'LIKE'
    },
    {
      type: 'select',
      field: 'status',
      name: 'Төлөв',
      op: '=',
      value: [
        {
          text: 'Веб-д ороогүй',
          value: 'NEW'
        },
        {
          text: 'Веб-д орсон',
          value: 'Active'
        },
        {
          value: 'Rejected',
          text: 'Зөвшөөрөгдөөгүй'
        }
      ]
    }
  ];

  return (
    <Page className={classes.root} title="Зарууд">
      <SearchBar
        onSearch={onSearch}
        filterable
        filterObject={filterObject}
        onFilter={onFilter}
        onClear={onClear}
      />
      <Button
        style={{ marginLeft: 'auto', marginTop: 10 }}
        color="primary"
        onClick={handleDownload}
        size="small"
        variant="outlined">
        <CloudDownloadIcon /> Татаж авах (XLSX)
      </Button>
      <Results className={classes.results} filter={filter} />
    </Page>
  );
};

export default OrderManagementList;
