import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useQuill } from 'react-quilljs';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { cancelationService } from 'service';
import './style.css';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const ContentDetail = props => {
  const { category, figure, className, refresh, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [contentDetail, setContentDetail] = useState({
    id: null,
    name: '',
    title: '',
    description: '',
    section1desc: '',
    section1title: '',
    section2desc: '',
    section2title: '',
    section3desc: '',
    section3title: ''
  });

  const { register, handleSubmit, reset } = useForm();

  const { quill, quillRef } = useQuill();

  const onSubmit = data => {
    const html = quill.root.innerHTML;
    dispatch(loadingActions.setLoader(true));
    cancelationService
      .update({ ...data, description: html, id: contentDetail.id })
      .then(
        r => {
          if (r) {
            setContentDetail({
              ...data,
              description: html,
              id: contentDetail.id
            });
            refresh();
            dispatch(loadingActions.setLoader(false));
          }
        },
        err => {
          toast('Алдаа гарлаа');
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  useEffect(() => {
    if (category?.id) {
      setContentDetail(category);
      reset(category);
      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(category?.description || '');
      }
    }
  }, [category, quill, reset]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid item md={12} xs={12}>
            <h1>
              {contentDetail.name} - <em>{contentDetail.title}</em>
            </h1>
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(contentDetail.description)
                }}
              />
            }
            <div className="sectionDiv allofdiv">
              <div className="divEdit1 allofdiv">
                <div>
                  {contentDetail.section1desc}
                  <span className="spanEdit">
                    {contentDetail.section1title}
                  </span>
                </div>
              </div>
              <div className="divEdit2 allofdiv">
                <div>
                  {contentDetail.section2title}
                  <span className="spanEdit">{contentDetail.section2desc}</span>
                </div>
              </div>
              <div className="divEdit3 allofdiv">
                <div>
                  {contentDetail.section3title}
                  <span className="spanEdit">{contentDetail.section3desc}</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className="row">
              <div className="col-md-12 form-group">
                <label>Нэр</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Нэр"
                  name="name"
                  defaultValue={contentDetail?.name || ''}
                  {...register('name')}
                />
              </div>
              <div className="col-md-12 form-group">
                <label>Гарчиг</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Гарчиг"
                  name="title"
                  defaultValue={contentDetail?.title || ''}
                  {...register('title')}
                />
              </div>
              <div className="col-md-12 form-group">
                <label>Тайлбар</label>
                <Form.Group className="mb-3" style={{ minHeight: '200px' }}>
                  <div ref={quillRef} style={{ minHeight: '200px' }} />
                </Form.Group>
              </div>
              <div className="row form-group">
                <div className="col-md-6 form-group">
                  <label>Хэсэг 1 гарчиг</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Хэсэг 1 гарчиг"
                    name="section1title"
                    defaultValue={contentDetail?.section1title || ''}
                    {...register('section1title')}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>Хэсэг 1 Тайлбар</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Хэсэг 1 Тайлбар"
                    name="section1desc"
                    defaultValue={contentDetail?.section1desc || ''}
                    {...register('section1desc')}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6 form-group">
                  <label>Хэсэг 2 гарчиг</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Хэсэг 2 гарчиг"
                    name="section2title"
                    defaultValue={contentDetail?.section2title || ''}
                    {...register('section2title')}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>Хэсэг 2 Тайлбар</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Хэсэг 2 Тайлбар"
                    name="section2desc"
                    defaultValue={contentDetail?.section2desc || ''}
                    {...register('section2desc')}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6 form-group">
                  <label>Хэсэг 3 гарчиг</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Хэсэг 3 гарчиг"
                    name="section3title"
                    defaultValue={contentDetail?.section3title || ''}
                    {...register('section3title')}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>Хэсэг 3 Тайлбар</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Хэсэг 3 Тайлбар"
                    name="section3desc"
                    defaultValue={contentDetail?.section3desc || ''}
                    {...register('section3desc')}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            // onClick={save}
          >
            Хадгалах
          </Button>
        </CardActions>
      </Form>
    </Card>
  );
};

ContentDetail.propTypes = {
  className: PropTypes.string
};

export default ContentDetail;
