import { sendRequest } from './middleware';



const getAll = async (skip, take, sort = [], filter = []) => {

    let config = {
        method: 'post',
        url: '/api/contact/get',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            skip: skip,
            take: take,
            sort: sort,
            filter: filter
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const getPostCount = async () => {
    let config = {
        method: 'get',
        url: '/api/contact/total',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const resp = await sendRequest(config);
    return resp;
}

const create = async (body) => {
    let config = {
        method: 'POST',
        url: '/api/contact/create',
        headers: {
            'Content-Type': 'application/json',
        },
        data: body
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const update = async (postObject) => {
    let config = {
        method: 'PUT',
        url: '/api/contact/update',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};

const deleteItem = async (id) => {
    let config = {
        method: 'DELETE',
        url: '/api/contact/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};



export const contactService = {
    getAll,
    create,
    update,
    deleteItem,
    getPostCount
};