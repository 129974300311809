import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { userService } from '../../../../service';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Request = props => {
  const { className, request, history, ...rest } = props;

  const classes = useStyles();
  const [customer, setCustomer] = useState();

  useEffect(() => {}, []);

  // const fetchCustomer = id => {
  //   userService.userDetail(id).then(response => {
  //     if (response.success) {
  //       setCustomer(response.data);
  //     }
  //   });
  // };

  if (!request) {
    return null;
  }

  const formatPrice = price => {
    return new Intl.NumberFormat().format(Number(price).toFixed(2)) + '₮';
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title="Түрээслэх хүсэлт" />
          <Divider />
          <CardContent>
            <div className="row" style={{ paddingTop: 20 }}>
              <div className="col-6">
                <Table>
                  <TableBody>
                    <TableRow selected>
                      <TableCell>Хүсэлт гаргасан</TableCell>
                      <TableCell>
                        <b>{moment(request.postDate).format('DD/MM/YYYY')}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Түрээслэж эхлэх</TableCell>
                      <TableCell>
                        <b>{moment(request.startDate).format('DD/MM/YYYY')}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Түрээслэж дуусах</TableCell>
                      <TableCell>
                        <b>
                          {moment(new Date(request.endDate)).format(
                            'DD/MM/YYYY'
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>Төлбөр төлсөн</TableCell>
                      <TableCell>
                        <b>
                          {moment(new Date(request.paidDate)).format(
                            'DD/MM/YYYY'
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Хугацаа</TableCell>
                      <TableCell>
                        <b>{request.duration + ' өдөр'}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="col-6">
                <Table>
                  <TableBody>
                    <TableRow selected>
                      <TableCell>Төлбөр</TableCell>
                      <TableCell>
                        <b>{formatPrice(request.price)}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Шимтгэл</TableCell>
                      <TableCell>
                        <b>{formatPrice(request.fee)}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Нийт үнэ</TableCell>
                      <TableCell>
                        <b>{formatPrice(request.totalPrice)}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>QPAY төлбөрийн дугаар</TableCell>
                      <TableCell>
                        <b>{request.qpayInvoiceId}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Төлөв</TableCell>
                      <TableCell>
                        <b>{request.status}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Request.propTypes = {
  className: PropTypes.string
};

export default Request;
