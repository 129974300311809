
const axios = require('axios');

export const sendRequest = async config => {
  try {
    const token = JSON.parse(localStorage.getItem('user'))?.access_token;
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
      const response = await axios(config);
      const resp = response.data;
      return resp;
    } else {
      // logout action
    }
  } catch (e) {
    if (e.response.data.statusCode == 401) {
      localStorage.removeItem('user');
    }
  }
};
