import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import { useDispatch } from 'react-redux';
import { emailTemplateService } from 'service';
import { PageDetail } from './components';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  }
}));

const General = props => {
  const { className, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedConfig, setSelectedConfig] = useState({});
  const [configs, setConfigs] = useState([]);
  const [active, setActive] = useState(null);

  useEffect(() => {
    const getpage = () => {
      dispatch(loadingActions.setLoader(true));
      emailTemplateService.getAllEmails().then(
        r => {
          setConfigs(r);
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
    };
    getpage();
    setSelectedConfig({});
  }, [dispatch]);

  const onConfigSelect = config => {
    setSelectedConfig(config);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent className={classes.content}>
            <ListGroup style={{ width: '100%' }}>
              {configs?.map((config, idx) => {
                return (
                  <ListGroup.Item
                    key={idx}
                    onClick={r => {
                      setActive(idx);
                      onConfigSelect(config);
                    }}
                    active={active === idx ? true : false}>
                    {config.description}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} md={6} xl={8} xs={12}>
        <PageDetail config={selectedConfig} />
      </Grid>
    </Grid>
  );
};

General.propTypes = {
  className: PropTypes.string
};

export default General;
