import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, colors, Divider, Grid, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { configService } from 'service';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const GeneralSettings = props => {
  const { config, className, ...rest } = props;


  const [configValue, setConfigValue] = useState("")
  const classes = useStyles();

  const handleChange = event => {
    setConfigValue(event.target.value);
    event.persist();
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  const input = useRef(null)
  const input1 = useRef(null)

  useEffect(() => {
    input.current.value = config?.key || "";
    input1.current.value = config?.title || "";
    setConfigValue(config?.value);
  }, [config])

  const save = () => {
    configService.configEdit({ ...config, key: config.key, value: configValue, title: input1.current.value }).then(r => {
      toast("success")
    }, err => {
      toast("Error occured please contact Admin")
    })
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form onSubmit={handleSubmit}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                inputRef={input}
                fullWidth
                name="Key"
                disabled
                // onChange={handleChange}
                required
                defaultValue=""
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                inputRef={input1}
                fullWidth
                name="title"
                defaultValue=""
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Form.Control as="textarea" rows={10}
                onChange={handleChange}
                value={configValue} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            onClick={save}
          >
            Save Changes
          </Button>
        </CardActions>
      </form>

    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string
};

export default GeneralSettings;