import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { userService } from '../../../../service';
import { CustomerInfo, OtherActions } from './components';
import { verificationService } from '../../../../service';


const useStyles = makeStyles(() => ({
  root: {}
}));

const Summary = props => {
  const { className, id, ...rest } = props;

  const classes = useStyles();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    const fetchCustomer = () => {
      verificationService.verificationDetails(id).then(response => {
        if (response?.success) {
          userService.userDetail(response?.data?.userId).then(response => {
            if (response.success) {
              setCustomer(response.data);
            }
          });
        }
      });
    };

    fetchCustomer();
  }, [id]);

  if (!customer) {
    return null;
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <CustomerInfo customer={customer} id={id} />
      </Grid>
      <Grid
        item
        lg={8}
        md={6}
        xl={9}
        xs={12}
      >
        <OtherActions customer={customer} id={id} />
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  className: PropTypes.string
};

export default Summary;
