import * as actionTypes from 'actions';

let user = JSON.parse(
  localStorage.getItem('user') ? localStorage.getItem('user') : '{}'
);

const initialState = user ? { loggedIn: true, user } : {};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      localStorage.setItem('user', JSON.stringify(action.state));
      return {
        loggedIn: true,
        user: action.state
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      localStorage.removeItem('user');
      localStorage.removeItem('permissions');
      return {
        loggedIn: false,
        user: undefined
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
