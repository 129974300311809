import {
  Button,
  Card,
  CardActions,
  CardContent,
  colors,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Label, Page, SearchBar } from 'components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { verificationService } from '../../service';

import { loadingActions } from 'actions';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const VerificationList = () => {
  const classes = useStyles();

  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  useEffect(() => {
    fetchCustomers(page * rowsPerPage, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomers = (skip, take, sort = [], filter = []) => {
    dispatch(loadingActions.setLoader(true));
    verificationService.getAll(skip, take, sort, filter).then(
      r => {
        setCustomers(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const handleChangePage = (event, pg) => {
    setPage(pg);
    fetchCustomers(pg * rowsPerPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    const value = event.target.value;
    setRowsPerPage(value);
    fetchCustomers(page * value, value);
  };

  const handleFilter = () => {};
  const handleSearch = searchValue => {
    fetchCustomers(
      page * rowsPerPage,
      rowsPerPage,
      [],
      [{ field: 'username', op: 'LIKE', qry: searchValue }]
    );
  };

  return (
    <Page className={classes.root} title="Хэрэглэгч баталгаажуулах хүсэлт">
      <SearchBar onFilter={handleFilter} onSearch={handleSearch} />
      {customers && (
        <div className={classes.results}>
          <Card>
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>email</TableCell>
                        <TableCell>Төлөв</TableCell>
                        <TableCell>Үүсгэсэн</TableCell>
                        <TableCell>Баталсан</TableCell>
                        <TableCell>Баталсан админ</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.slice(0, rowsPerPage).map(customer => (
                        <TableRow hover key={customer.id}>
                          <TableCell>{customer.id}</TableCell>
                          <TableCell>
                            <div className={classes.nameCell}>
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={'/management/customers/' + customer.id}
                                  variant="h6">
                                  {customer.user.email}
                                </Link>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Label
                              color={
                                customer.verified
                                  ? colors.green[600]
                                  : colors.orange[600]
                              }>
                              {customer.verified
                                ? 'Баталгаажсан'
                                : 'Баталгаажаагүй'}
                            </Label>
                          </TableCell>
                          <TableCell>
                            {moment(customer.postDate).format(
                              'DD MMM YYYY | hh:mm'
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(customer.verifiedDate).format(
                              'DD MMM YYYY | hh:mm'
                            )}
                          </TableCell>
                          <TableCell>{customer.verified?.email}</TableCell>
                          {(permissions.includes('VERIF_DETAIL') ||
                            permissions.includes('ALL')) && (
                            <TableCell align="right">
                              <Button
                                color="primary"
                                component={RouterLink}
                                size="small"
                                to={'/verifications/' + customer.id}
                                variant="outlined">
                                Харах
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </CardActions>
          </Card>
        </div>
      )}
    </Page>
  );
};

export default VerificationList;
