import {
  Card, CardContent,
  CardHeader, Divider, Grid, Table,
  TableBody,
  TableCell, TableRow, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { rentCancelService } from '../../../../service';

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

import { useDispatch } from 'react-redux';

import { loadingActions } from 'actions';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Request = props => {
  const { className, request, history, ...rest } = props;

  const classes = useStyles();
  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setData(request);
  }, [request]);

  const trans = {
    NEW: 'Шинэ',
    ACCEPTED: 'Зөвшөөрсөн',
    DECLINED: 'Татгалзсан'
  };

  const changeStatus = status => {
    dispatch(loadingActions.setLoader(true));
    rentCancelService.changeStatus({ id: request.id, status: status }).then(
      response => {
        dispatch(loadingActions.setLoader(false));
        if (response) {
          setData({ ...request, status: status });
          toast('Хадгалагдлаа');
        } else {
          toast('Алдаа гарлаа');
        }
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  if (!request) {
    return null;
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title="Түрээслэх хүсэлт" />
          <Divider />
          <CardContent>
            <div className="row" style={{ paddingTop: 20 }}>
              <div className="col-6">
                <Table>
                  <TableBody>
                    <TableRow selected>
                      <TableCell>Хүсэлт гаргасан</TableCell>
                      <TableCell>
                        <b>{moment(data?.postDate).format('DD/MM/YYYY')}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="col-6">
                <Table>
                  <TableBody>
                    <TableRow selected>
                      <TableCell>Төлөв</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          name="option"
                          onChange={e => {
                            changeStatus(e.target.value);
                          }}
                          select
                          SelectProps={{ native: true }}
                          value={data?.status}
                          variant="outlined">
                          {['NEW', 'ACCEPTED', 'DECLINED'].map(option => (
                            <option key={option} value={option}>
                              {trans[option]}
                            </option>
                          ))}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="col-12 pt-4">
                <Form.Control
                  as="textarea"
                  rows={3}
                  disabled={true}
                  value={data?.description}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Request.propTypes = {
  className: PropTypes.string
};

export default Request;
