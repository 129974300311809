import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import React, { useEffect, useState } from 'react';
import { postsService } from '../../service';
import { OrderInfo, OrderItems } from './components';

import { useDispatch } from 'react-redux';

import { loadingActions } from 'actions';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const OrderManagementDetails = props => {
  const { match, postId } = props;
  const classes = useStyles();
  const [order, setOrder] = useState(null);
  let id = null;
  if (!postId) id = match.params.id;
  const dispatch = useDispatch();

  const realId = postId ? postId : id;

  useEffect(() => {
    const fetchOrder = () => {
      postsService.postDetail(realId).then(response => {
        if (response.success) {
          setOrder(response.data);
        }
      });
    };
    fetchOrder();
  }, [realId]);

  if (!order) {
    return null;
  }

  const handleChange = event => {
    event.persist();
    setOrder({ ...order, status: event.target.value });
  };

  const handleDescChange = event => {
    event.persist();
    setOrder({ ...order, description: event.target.value });
  };

  const handleAddrChange = (city, state, district, address) => {
    setOrder({
      ...order,
      address: address,
      city: city,
      state: state,
      district: district
    });
  };

  const onSave = event => {
    event.persist();
    dispatch(loadingActions.setLoader(true));
    delete order.postAttachments;
    postsService.postEdit(order).then(
      response => {
        dispatch(loadingActions.setLoader(false));
        if (response == true) {
          toast('Хадгаллаа');
        } else {
          toast('Алдаа гарлаа');
        }
      },
      err => {
        toast('Алдаа гарлаа');
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  return (
    <Page className={classes.root} title="Зарын дэлгэрэнгүй">
      <Grid className={classes.container} container spacing={3}>
        <Grid item md={4} xl={3} xs={12}>
          <OrderInfo
            order={order}
            statusChange={handleChange}
            onSave={onSave}
          />
        </Grid>
        <Grid item md={8} xl={9} xs={12}>
          <OrderItems
            order={order}
            descChange={handleDescChange}
            addressChange={handleAddrChange}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default OrderManagementDetails;
