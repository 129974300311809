import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { rentRequestService } from 'service';
import React, { useState, useEffect } from 'react';
import { Label } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const options = [
  {
    name: 'Төлөгдөөгүй',
    value: 'UNPAID'
  },
  {
    name: 'Төлбөр төлөгдсөн',
    value: 'PAID'
  },
  {
    name: 'Зөвшөөрсөн',
    value: 'ACCEPTED'
  },
  {
    name: 'Хугацаа дууссан',
    value: 'EXPIRED'
  },
  {
    name: 'Татгалзсан',
    value: 'DECLINED'
  }
];

const paymentStatusColors = {
  UNPAID: colors.orange[600],
  PAID: colors.blue[600],
  ACCEPTED: colors.green[600],
  EXPIRED: colors.grey[600],
  DECLINED: colors.red[600]
};

const Results = props => {
  const dispatch = useDispatch();
  const { className, filter, ...rest } = props;

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listData, setListData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  useEffect(() => {
    dispatch(loadingActions.setLoader(true));
    rentRequestService
      .getAll(
        page * rowsPerPage,
        rowsPerPage,
        { field: 'postDate', dir: 'DESC' },
        filter
      )
      .then(
        response => {
          setListData(response.data);

          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  }, [rowsPerPage, page, filter]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {listData.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(listData.length / rowsPerPage)}
      </Typography>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Зар</TableCell>
                    <TableCell>Хугацаа</TableCell>
                    <TableCell>Төлөв</TableCell>
                    <TableCell>Түрээслэгч</TableCell>
                    <TableCell>Хүсэлт гаргасан</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listData.map(data => (
                    <TableRow hover key={data.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={'/management/orders/' + data.post?.id}>
                          {'#' + data.post?.id}
                        </Link>
                      </TableCell>
                      <TableCell>{data?.duration + ' Хоног'}</TableCell>
                      <TableCell>
                        <Label
                          color={paymentStatusColors[data.status]}
                          variant="outlined">
                          {options.find(x => x.value == data.status)?.name}
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={'/management/customers/' + data.user?.id}>
                          {data.user?.email}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {moment(data.postDate).format('YYYY-MM-DD | hh:mm')}
                      </TableCell>
                      <TableCell align="right">
                        {(permissions.includes('RENT_REQUEST_DETAIL') ||
                          permissions.includes('ALL')) && (
                          <Button
                            color="primary"
                            component={RouterLink}
                            size="small"
                            to={'/rentrequests/detail/' + data.id}
                            variant="outlined">
                            Харах
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={listData.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listData: PropTypes.array.isRequired
};

Results.defaultProps = {
  listData: []
};

export default Results;
