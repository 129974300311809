import { colors, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Summary } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const VerificationDetails = props => {
  const { match } = props;
  const classes = useStyles();
  const { id } = match.params;

  const tab = { value: 'summary', label: 'Үндсэн' };

  return (
    <Page className={classes.root} title="Баталгаажуулах хүсэлт">
      <Tabs
        className={classes.tabs}
        scrollButtons="auto"
        value={tab.value}
        variant="scrollable">
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Summary id={id} />
      </div>
    </Page>
  );
};

VerificationDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default VerificationDetails;
