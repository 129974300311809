import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { categoryService } from 'service';
import './style.css';
import Resizer from 'react-image-file-resizer';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const CategoryDetail = props => {
  const { category, className, refresh, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedFileBg, setSelectedFileBg] = useState(null);

  const [categoryDetail, setCategoryDetail] = useState({
    id: null,
    name: '',
    description: '',
    isActive: true,
    isOnHome: false,
    parentId: null,
    catIcon: '',
    color: '',
    postCount: 0
  });

  const { register, handleSubmit, reset } = useForm();
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  const onSubmit = data => {
    dispatch(loadingActions.setLoader(true));
    categoryService
      .categoryEdit({
        ...categoryDetail,
        name: data.name,
        isOnHome: data.isOnHome,
        isActive: data.isActive
      })
      .then(
        r => {
          if (r) {
            setCategoryDetail({
              ...categoryDetail,
              name: data.name,
              isOnHome: data.isOnHome,
              isActive: data.isActive
            });
            dispatch(loadingActions.setLoader(false));
          }
        },
        err => {
          toast('Алдаа гарлаа');
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  const onFileChangeBg = event => {
    setSelectedFileBg(event.target.files[0]);
  };

  const onFileUploadBg = () => {
    if (selectedFileBg) {
      dispatch(loadingActions.setLoader(true));
      Resizer.imageFileResizer(
        selectedFileBg,
        640,
        426,
        'PNG',
        100,
        0,
        uri => {
          const formData = new FormData();

          formData.append('image', uri, uri.name);
          axios.post('/api/upload', formData).then(
            r => {
              if (r.data) {
                categoryService
                  .categoryEdit({ ...categoryDetail, catIcon: r.data })
                  .then(
                    r => {
                      categoryService.categoryDetail(categoryDetail.id).then(
                        r => {
                          if (r) {
                            setCategoryDetail(r);
                            dispatch(loadingActions.setLoader(false));
                          }
                        },
                        err => {
                          toast('Алдаа гарлаа');
                          dispatch(loadingActions.setLoader(false));
                        }
                      );
                    },
                    err => {
                      toast('Алдаа гарлаа');
                      dispatch(loadingActions.setLoader(false));
                    }
                  );
              } else {
                toast('Алдаа гарлаа');
                dispatch(loadingActions.setLoader(false));
              }
            },
            err => {
              toast('Алдаа гарлаа');
              dispatch(loadingActions.setLoader(false));
            }
          );
        },
        'file',
        640,
        426
      );
    }
  };

  useEffect(() => {
    if (category?.id) {
      setCategoryDetail(category);
      reset(category);
    }
  }, [category, reset]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid item md={6} xs={6}>
            <div className="acr-category">
              <div className="acr-category-thumb">
                <img
                  src={
                    categoryDetail?.catIcon
                      ? '/files/' + categoryDetail?.catIcon
                      : 'http://androthemes.com/themes/react/acres/assets/img/categories/1.jpg'
                  }
                  alt="category"
                />
                <div className="acr-category-body">
                  <h5>
                    <p>{categoryDetail?.name}</p>{' '}
                  </h5>
                  <span>{categoryDetail?.postCount} зар</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className="row">
              <div className="col-md-12 form-group">
                <label>Нэр</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Нэр"
                  name="fname"
                  defaultValue={categoryDetail?.name || ''}
                  {...register('name', {
                    required: true
                  })}
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  name="isOnHome"
                  type="checkbox"
                  {...register('isOnHome')}
                />

                <label>Нүүр хуудсанд харуулах</label>
              </div>
              <div className="col-md-6 form-group">
                <input
                  name="isActive"
                  type="checkbox"
                  {...register('isActive')}
                />
                <label>Идэвхитэй</label>
              </div>
              <div className="col-md-12 form-group">
                <label>Арын зураг</label>
                <br />
                <input type="file" onChange={onFileChangeBg} />
                <Button
                  onClick={onFileUploadBg}
                  variant="contained"
                  color="secondary">
                  Оруулах
                </Button>
              </div>
            </div>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          {(permissions.includes('CATEGORY_ACTIONS') ||
            permissions.includes('ALL')) && (
            <Button
              className={classes.saveButton}
              type="submit"
              variant="contained"
              // onClick={save}
            >
              Хадгалах
            </Button>
          )}
        </CardActions>
      </Form>
    </Card>
  );
};

CategoryDetail.propTypes = {
  className: PropTypes.string
};

export default CategoryDetail;
