import { authService } from '../service';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

const login = (username, password) => dispatch => {
  authService.login(username, password).then(async resp => {
    if (resp.success) {
      const permissions = await authService.getUserPermissions(
        resp.data.roles?.id
      );
      localStorage.setItem('permissions', JSON.stringify(permissions));
      dispatch({
        type: SESSION_LOGIN,
        state: resp.data
      });
    }
  });
};

const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  });

export const userActions = {
  login,
  logout
};
