import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  colors
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, withStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import axios from 'axios';
import { Page } from 'components';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Resizer from 'react-image-file-resizer';
import { useQuill } from 'react-quilljs';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import { blogService } from 'service';
import React, { useState } from 'react';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  paper: {
    paddingBottom: 50
  },
  list: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto'
  }
}));

const CreateBlog = props => {
  const [selectedFileBg, setSelectedFileBg] = useState(null);
  const dispatch = useDispatch();
  const { quill, quillRef } = useQuill();
  const [data, setData] = useState({
    title: '',
    description: '',
    status: 'UNPUBLISHED',
    image: '',
    content: ''
  });
  const classes = useStyles();

  const handleSubmit = event => {
    event.preventDefault();
    const postData = data;
    postData.content = quill.root.innerHTML;
    dispatch(loadingActions.setLoader(true));
    blogService.create(data).then(
      r => {
        dispatch(loadingActions.setLoader(false));
        toast('Хадгалагдсан');
      },
      err => {
        toast('Error');
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const onFileChangeBg = event => {
    setSelectedFileBg(event.target.files[0]);
  };

  const onFileUploadBg = () => {
    if (selectedFileBg) {
      dispatch(loadingActions.setLoader(true));
      Resizer.imageFileResizer(
        selectedFileBg,
        640,
        426,
        'PNG',
        100,
        0,
        uri => {
          const formData = new FormData();
          formData.append('image', uri, uri.name);
          axios.post('/api/upload', formData).then(
            r => {
              if (r.data) {
                setData({ ...data, image: r.data });
              } else {
                toast('Алдаа гарлаа');
                dispatch(loadingActions.setLoader(false));
              }
            },
            err => {
              toast('Алдаа гарлаа');
              dispatch(loadingActions.setLoader(false));
            }
          );
        },
        'file',
        640,
        426
      );
    }
  };

  return (
    <Page className={classes.root} title="Блог">
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <CardHeader title="Блог" />
                <CardActions className={classes.actions}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      justifyItems: 'center',
                      alignItems: 'center'
                    }}>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={data.status}
                      onChange={e => {
                        setData({ ...data, status: e.target.value });
                      }}
                      input={<BootstrapInput />}>
                      <MenuItem value="PUBLISHED">Идэвхитэй</MenuItem>
                      <MenuItem value="UNPUBLISHED">Идэвхигүй</MenuItem>
                    </Select>
                    <Button
                      type="submit"
                      style={{
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center'
                      }}>
                      <EditIcon className={classes.buttonIcon} />
                      Хадгалах
                    </Button>
                  </div>
                </CardActions>
                <CardContent className={classes.content}>
                  <Grid item md={12} xs={12}>
                    <fieldset>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledSelect">Гарчиг</Form.Label>
                        <Form.Control
                          name="title"
                          placeholder="Гарчиг"
                          value={data.title}
                          defaultValue=""
                          onChange={e => {
                            setData({ ...data, title: e.target.value });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Зураг</Form.Label>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 5
                          }}>
                          <Form.Control
                            type="file"
                            name="image"
                            placeholder="Зураг"
                            defaultValue=""
                            onChange={onFileChangeBg}
                          />
                          <Button
                            onClick={onFileUploadBg}
                            variant="contained"
                            color="secondary">
                            Оруулах
                          </Button>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Товч тайлбар</Form.Label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Тайлбар"
                          name="value"
                          rows={5}
                          value={data.description}
                          onChange={e => {
                            setData({ ...data, description: e.target.value });
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        style={{ minHeight: '300px' }}>
                        <Form.Label>Дэлгэрэнгүй</Form.Label>
                        <div ref={quillRef} style={{ minHeight: '500px' }} />
                      </Form.Group>
                    </fieldset>
                  </Grid>
                </CardContent>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

CreateBlog.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default CreateBlog;
