import { sendRequest } from './middleware';



const getAll = async () => {

    let config = {
        method: 'get',
        url: '/api/content/get?group=text',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const resp = await sendRequest(config);
    if (resp.data)
        return resp.data;
    else return [];
};


const create = async (body) => {
    body.group = "text";
    let config = {
        method: 'POST',
        url: '/api/content/create',
        headers: {
            'Content-Type': 'application/json',
        },
        data: body
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const update = async (postObject) => {
    let config = {
        method: 'PUT',
        url: '/api/content/update',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return false;
};

const deleteItem = async (id) => {
    let config = {
        method: 'DELETE',
        url: '/api/content/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};

const detail = async (id) => {
    let config = {
        method: 'GET',
        url: '/api/content/detail/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return false;
};



export const textPageService = {
    getAll,
    create,
    update,
    deleteItem,
    detail
};