import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  CardActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useDispatch } from 'react-redux';
import { logService } from 'service';

import { loadingActions } from 'actions';
import CommandList from './commandList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  inner: {
    width: '100%'
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  }
}));

const sort = { field: 'created_at', dir: 'DESC' };

const Logs = props => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = () => {
    dispatch(loadingActions.setLoader(true));
    logService.getAll(page * rowsPerPage, rowsPerPage, sort).then(
      r => {
        setLogs(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const handleChangePage = (event, pg) => {
    setPage(pg);
    dispatch(loadingActions.setLoader(true));
    logService.getAll(pg * rowsPerPage, rowsPerPage, sort).then(
      r => {
        setLogs(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const handleChangeRowsPerPage = event => {
    const value = event.target.value;
    setRowsPerPage(value);

    dispatch(loadingActions.setLoader(true));
    logService.getAll(page * value, value, sort).then(
      r => {
        setLogs(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  return (
    <Page className={classes.root} title="Хэрэглэгчийн мэдээлэл">
      <Card>
        <CardHeader title="Log" />
        <CardContent className={classes.content}>
          <PerfectScrollbar options={{ suppressScrollY: true }}>
            <div className={classes.inner}>
              <Table>
                <TableBody>
                  {logs.map(log => (
                    <TableRow key={log?.id}>
                      <TableCell>{log.user?.email}</TableCell>
                      <TableCell>{CommandList[log?.command]}</TableCell>
                      <TableCell align="right">
                        {moment(log?.created_at).format('YYYY/MM/DD | hh:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={-1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
    </Page>
  );
};

export default Logs;
