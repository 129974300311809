import { sendRequest } from './middleware';



const getAll = async () => {

    let config = {
        method: 'get',
        url: '/api/admin/config',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const configDetail = async (id) => {
    let config = {
        method: 'get',
        url: '/api/admin/config/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const configEdit = async (postObject) => {
    let config = {
        method: 'POST',
        url: '/api/admin/config',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};


export const configService = {
    getAll,
    configDetail,
    configEdit
};