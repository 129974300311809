import { sendRequest } from './middleware';



const getAll = async (group) => {

    let config = {
        method: 'get',
        url: '/api/pages/get?group=' + group,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const create = async (body) => {
    let config = {
        method: 'POST',
        url: '/api/pages/create',
        headers: {
            'Content-Type': 'application/json',
        },
        data: body
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const update = async (postObject) => {
    let config = {
        method: 'PUT',
        url: '/api/pages/update',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};

const deleteItem = async (id) => {
    let config = {
        method: 'DELETE',
        url: '/api/pages/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};



export const pagesService = {
    getAll,
    create,
    update,
    deleteItem,
};