import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import { Label } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { verificationService } from '../../../../../../service';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CustomerInfo = props => {
  const { customer, id, className, ...rest } = props;
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  const classes = useStyles();

  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const handleEditOpen = () => {
    verificationService.verificationEdit({ ...data, status: 'verified' }).then(
      r => {
        if (r.success) {
          toast('Success');
        }
      },
      err => {
        toast('Error occured please contact Admin');
      }
    );
  };

  const handleEditClose = () => {
    verificationService.verificationEdit({ ...data, status: 'rejected' }).then(
      r => {
        if (r.success) {
          toast('Success');
        }
      },
      err => {
        toast('Error occured please contact Admin');
      }
    );
  };

  useEffect(() => {
    dispatch(loadingActions.setLoader(true));
    const fetchOrders = () => {
      verificationService.verificationDetails(id).then(
        response => {
          dispatch(loadingActions.setLoader(false));
          if (response?.success) {
            setData(response.data);
          }
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
    };
    fetchOrders();
  }, [id, dispatch]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Мэдээлэл" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{customer?.email}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Утас</TableCell>
              <TableCell>{customer?.mobile}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Овог</TableCell>
              <TableCell>{customer?.firstname}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Нэр</TableCell>
              <TableCell>{customer?.lastname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Баталгаажсан</TableCell>
              <TableCell>
                {customer?.verified}
                <div>
                  <Label
                    color={
                      customer?.verified
                        ? colors.green[600]
                        : colors.orange[600]
                    }>
                    {customer?.verified ? 'verified' : 'not verified'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        {(permissions.includes('VERIF_ACTION') ||
          permissions.includes('ALL')) && (
          <div
            style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
            <Button onClick={handleEditOpen}>
              <DoneIcon className={classes.buttonIcon} />
              Зөвшөөрөх
            </Button>
            <Button onClick={handleEditClose}>
              <BlockIcon className={classes.buttonIcon} />
              Татгалзах
            </Button>
          </div>
        )}
      </CardActions>
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default CustomerInfo;
