import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Filter = props => {
  const {
    open,
    onClose,
    onClear,
    onFilter,
    className,
    filters,
    initialValues,
    ...rest
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState({ ...initialValues });

  const handleClear = () => {
    onClear();
  };

  const handleFieldChange = (event, field, value, op) => {
    event.persist && event.persist();
    setValues(values => ({
      ...values,
      [field]: { value: value, op: op }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    onFilter && onFilter(values);
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary">
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Хаах
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <Divider />
            <div className={classes.contentSectionContent}>
              <div className={classes.contentSectionContent}>
                {filters.map((value, idx) => {
                  if (value.type === 'text') {
                    return (
                      <div className={classes.formGroup} key={'asdsa' + idx}>
                        <TextField
                          className={classes.field}
                          fullWidth
                          label={value.name}
                          margin="dense"
                          name={value.name}
                          onChange={event =>
                            handleFieldChange(
                              event,
                              value.field,
                              event.target.value,
                              value.op
                            )
                          }
                          value={values ? values[value.field] : ''}
                          variant="outlined"
                        />
                      </div>
                    );
                  } else if (value.type === 'select') {
                    return (
                      <div
                        className={classes.formGroup}
                        key={'asdsa' + idx}
                        style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>{value.name}</label>
                        <ToggleButtonGroup
                          exclusive
                          onChange={(event, val) =>
                            value &&
                            handleFieldChange(event, value.field, val, value.op)
                          }
                          size="small"
                          value={values ? values[value.field]?.value : ''}
                          variant="outlined">
                          {value.value.map((val1, id1) => {
                            return (
                              <ToggleButton
                                key={'1111' + id1}
                                color="primary"
                                value={val1.value}>
                                {val1.text}
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </div>
                    );
                  }
                  return <></>;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button fullWidth onClick={handleClear} variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Устгах
          </Button>
          <Button color="primary" fullWidth type="submit" variant="contained">
            Шүүх
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Filter;
