import {
  Card,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useDispatch } from 'react-redux';
import { configService } from 'service';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  },
  removeBotton: {
    width: '100%'
  }
}));

const ConfigList = props => {
  const { className, onConfigSelect, ...rest } = props;

  const [configs, setConfigs] = useState([]);

  const [active, setActive] = useState(null);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingActions.setLoader(true))
    configService.getAll().then(r => {
      dispatch(loadingActions.setLoader(false))
      setConfigs(r);
    }, err => {
      dispatch(loadingActions.setLoader(false))
    });
  }, [dispatch]);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <ListGroup style={{ width: "100%" }}>
          {configs?.map((config, idx) => {
            return (
              <ListGroup.Item key={'config' + idx}
                onClick={(r) => {
                  setActive(idx);
                  onConfigSelect(config);
                }}
                active={active === idx ? true : false}
              >
                {config.key}
              </ListGroup.Item>
            )
          })
          }
        </ListGroup>
      </CardContent>
    </Card >
  );
};

ConfigList.propTypes = {
  className: PropTypes.string
};

export default ConfigList;
