import { sendRequest } from './middleware';

const getAll = async () => {
  let config = {
    method: 'get',
    url: '/api/EmailTemplate/get',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  return resp;
};

const getAllEmails = async () => {
  let config = {
    method: 'get',
    url: '/api/EmailTemplate/getEmails',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  return resp;
};

const update = async object => {
  let config = {
    method: 'put',
    url: '/api/EmailTemplate/update',
    headers: {
      'Content-Type': 'application/json'
    },
    data: object
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

export const emailTemplateService = {
  getAll,
  getAllEmails,
  update
};
