import {
  Card, CardContent, CardHeader, Divider, Table,
  TableBody, TableCell, TableRow
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { verificationService } from '../../../../../../service';

const OtherActions = props => {

  const { id } = props;

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchOrders = () => {
      verificationService.verificationDetails(id).then(response => {
        if (response?.success) {
          setData(response.data);
        }
      });
    };
    fetchOrders();
  }, [id]);

  return (
    <Card>
      <CardHeader title="Хүсэлт" />
      <Divider />
      <CardContent>
        <div style={{ display: 'flex', flexDirection: "row", gap: 10 }}>
          <img src={'/files/' + data?.front} style={{ width: '50%' }} alt="front" />
          <img src={'/files/' + data?.back} style={{ width: '50%' }} alt="back" />
        </div>
        <div className="row">
          <Table>
            <TableBody>
              <TableRow selected>
                <TableCell>Банк</TableCell>
                <TableCell>
                  {data?.bankName}
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell>Дансны дугаар</TableCell>
                <TableCell>
                  {data?.bankAccNo}
                </TableCell>
              </TableRow>
              <TableRow selected>
                <TableCell>Дансны нэр</TableCell>
                <TableCell>
                  {data?.bankAccName}
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell>Он сар</TableCell>
                <TableCell>
                  {moment(data?.postDate).format('DD/MM/YYYY HH:MM')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card >
  );
};

OtherActions.propTypes = {
  className: PropTypes.string
};

export default OtherActions;
