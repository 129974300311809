import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, colors, Divider, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useQuill } from 'react-quilljs';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { pagesService } from 'service';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const PageDetail = props => {
  const { config, className, refresh, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [title, setTitle] = useState("");

  const handleSubmit = event => {
    event.preventDefault();

    const data = config;
    data.value = quill.root.innerHTML;
    data.title = title;

    dispatch(loadingActions.setLoader(true))
    pagesService.update(data).then(r => {
      dispatch(loadingActions.setLoader(false))
      refresh();
      toast("Хадгалагдсан")
    }, err => {
      toast("Error")
      dispatch(loadingActions.setLoader(false))
    })
  };

  const { quill, quillRef } = useQuill();

  useEffect(() => {
    if (config && config.title) {
      setTitle(config?.title);
    } else {
      setTitle("");
    }
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(config?.value || "");
    }
  }, [config, quill])


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Form onSubmit={handleSubmit}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <fieldset>
                {/* <Form.Group className="mb-3">
                  <Form.Label htmlFor="disabledTextInput">Нэр</Form.Label>
                  <Form.Control name="name" placeholder="Нэр" value={config?.name} defaultValue="" />
                </Form.Group> */}
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="disabledSelect">Гарчиг</Form.Label>
                  <Form.Control name="title" placeholder="Гарчиг" value={title} defaultValue="" onChange={(e) => {
                    setTitle(e.target.value);
                  }} />
                </Form.Group>
                <Form.Group className="mb-3" style={{ minHeight: "300px" }}>
                  <div ref={quillRef} style={{ minHeight: "300px" }} />
                </Form.Group>
              </fieldset>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
          // onClick={save}
          >
            Хадгалах
          </Button>
        </CardActions>
      </Form>

    </Card>
  );
};

PageDetail.propTypes = {
  className: PropTypes.string
};

export default PageDetail;