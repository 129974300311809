import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import { Page } from 'components';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { contactService } from 'service';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  rootModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actionsModal: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const ContactRequestList = props => {
  const classes = useStyles();

  const { className, ...rest } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orders, setOrders] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(loadingActions.setLoader(true));
    contactService.getAll(page * rowsPerPage, rowsPerPage).then(
      response => {
        if (response.success) {
          setOrders(response.data);
        }
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );

    contactService.getPostCount().then(response => {
      setTotalRecord(response.total);
    });
  }, [rowsPerPage, page, dispatch]);

  return (
    <Page className={classes.root}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Typography color="textSecondary" gutterBottom variant="body2">
          {totalRecord} Records found. Page {page + 1} of{' '}
          {Math.ceil(totalRecord / rowsPerPage)}
        </Typography>
        <Modal onClose={onClose} open={openModal}>
          <Card {...rest} className={clsx(classes.rootModal, className)}>
            <form>
              <CardContent>
                <Typography
                  align="center"
                  gutterBottom
                  variant="h3"></Typography>
                <Grid className={classes.container} container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={selected.email}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Нэр"
                      name="name"
                      value={selected.name}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Утасны дугаар"
                      name="phone"
                      value={selected.phone}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Огноо"
                      name="postDate"
                      value={selected.postDate}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Тайлбар"
                      name="description"
                      value={selected.description}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.actionsModal}>
                <Button onClick={onClose} variant="contained">
                  Close
                </Button>
              </CardActions>
            </form>
          </Card>
        </Modal>
        <Card>
          <CardHeader title="Orders" />
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Нэр</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Утасны дугаар</TableCell>
                      <TableCell>Тайлбар</TableCell>
                      <TableCell>Хүсэлтийн огноо</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.slice(0, rowsPerPage).map(order => (
                      <TableRow key={order.id}>
                        <TableCell>{order.id}</TableCell>

                        <TableCell>{order.name}</TableCell>
                        <TableCell>{order.email}</TableCell>
                        <TableCell>{order.phone}</TableCell>
                        <TableCell>
                          {order.description?.substring(0, 50)}
                        </TableCell>
                        <TableCell>
                          {moment(order.postDate).format('DD MMM YYYY | hh:mm')}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              setSelected(order);
                              setOpenModal(true);
                            }}>
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={totalRecord}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card>
      </div>
    </Page>
  );
};

export default ContactRequestList;
