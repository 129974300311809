import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  colors
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/styles';
import { Page, SearchBar } from 'components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { userService } from 'service';
import React, { useEffect, useState } from 'react';
import { loadingActions } from 'actions';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const CustomerManagementList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  const handleChangePage = (event, pg) => {
    setPage(pg);
    dispatch(loadingActions.setLoader(true));
    userService
      .getAll(pg * rowsPerPage, rowsPerPage, { field: 'id', dir: 'DESC' })
      .then(
        r => {
          setCustomers(r.data);
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  const handleChangeRowsPerPage = event => {
    const value = event.target.value;
    setRowsPerPage(value);

    dispatch(loadingActions.setLoader(true));
    userService.getAll(page * value, value, { field: 'id', dir: 'DESC' }).then(
      r => {
        setCustomers(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  useEffect(() => {
    const fetchCustomers = () => {
      dispatch(loadingActions.setLoader(true));
      userService
        .getAll(page * rowsPerPage, rowsPerPage, { field: 'id', dir: 'DESC' })
        .then(
          r => {
            setCustomers(r.data);
            dispatch(loadingActions.setLoader(false));
          },
          err => {
            dispatch(loadingActions.setLoader(false));
          }
        );
    };

    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilter = val => {
    const filt = [];
    for (let fil in val) {
      if (val[fil])
        filt.push({ field: fil, op: val[fil].op, qry: val[fil].value });
    }
    dispatch(loadingActions.setLoader(true));
    userService
      .getAll(
        page * rowsPerPage,
        rowsPerPage,
        { field: 'id', dir: 'DESC' },
        filt
      )
      .then(
        r => {
          console.log(r);
          setCustomers(r.data);
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  const onClear = () => {
    dispatch(loadingActions.setLoader(true));
    userService
      .getAll(page * rowsPerPage, rowsPerPage, { field: 'id', dir: 'DESC' }, [])
      .then(
        r => {
          setCustomers(r.data);
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  const filterObject = [
    {
      type: 'text',
      field: 'email',
      name: 'Email',
      op: 'LIKE'
    },
    {
      type: 'text',
      field: 'firstname',
      op: 'LIKE',
      name: 'Нэр'
    },
    {
      type: 'select',
      field: 'isCreator',
      name: 'Төрөл',
      op: '=',
      value: [
        {
          text: 'Түрээслэгч',
          value: false
        },
        {
          text: 'Түрээслүүлэгч',
          value: true
        }
      ]
    },
    {
      type: 'select',
      field: 'EmailVerified',
      name: 'Email баталгаажуулалт',
      op: '=',
      value: [
        {
          text: 'Хийгээгүй',
          value: false
        },
        {
          text: 'Хийсэн',
          value: true
        }
      ]
    },
    {
      type: 'select',
      field: 'mobile_verified',
      name: 'Дугаар баталгаажуулалт',
      op: '=',
      value: [
        {
          text: 'Хийгээгүй',
          value: false
        },
        {
          text: 'Хийсэн',
          value: true
        }
      ]
    }
  ];

  const handleSearch = searchValue => {
    dispatch(loadingActions.setLoader(true));
    userService
      .getAll(
        page * rowsPerPage,
        rowsPerPage,
        [],
        [{ field: 'username', op: 'LIKE', qry: searchValue }]
      )
      .then(
        r => {
          setCustomers(r.data);
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  const handleDownload = async () => {
    const resp = await userService.exportData();

    const outputFilename = `${Date.now()}.xlsx`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([resp]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Page className={classes.root} title="Хэрэглэгч">
      <SearchBar
        onSearch={handleSearch}
        filterable
        filterObject={filterObject}
        onFilter={onFilter}
        onClear={onClear}
      />
      <Button
        style={{ marginLeft: 'auto', marginTop: 10 }}
        color="primary"
        onClick={handleDownload}
        size="small"
        variant="outlined">
        <CloudDownloadIcon /> Татаж авах (XLSX)
      </Button>

      {customers && (
        <div className={classes.results}>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {customers.length} Records found. Page {page + 1} of{' '}
            {Math.ceil(customers.length / rowsPerPage)}
          </Typography>
          <Card>
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Овог нэр</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Утас</TableCell>
                        <TableCell>Төрөл</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.map(customer => (
                        <TableRow hover key={customer.id}>
                          <TableCell>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row'
                              }}>
                              {`${customer.firstname} ${customer.lastname}`}
                              {customer.verified ? (
                                <VerifiedUserIcon
                                  style={{
                                    color: colors.green[600],
                                    marginLeft: 10
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row'
                              }}>
                              {customer.email}
                              {customer.EmailVerified ? (
                                <CheckIcon
                                  style={{
                                    color: colors.green[600],
                                    marginLeft: 10
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row'
                              }}>
                              {customer.mobile}
                              {customer.mobile_verified ? (
                                <CheckIcon
                                  style={{
                                    color: colors.green[600],
                                    marginLeft: 10
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            {customer.isCreator
                              ? 'Түрээслүүлэгч'
                              : 'Түрээслэгч'}
                          </TableCell>
                          {(permissions.includes('USER_DETAIL') ||
                            permissions.includes('ALL')) && (
                            <TableCell align="right">
                              <Button
                                color="primary"
                                component={RouterLink}
                                size="small"
                                to={'/management/customers/' + customer.id}
                                variant="outlined">
                                Харах
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </CardActions>
          </Card>
        </div>
      )}
    </Page>
  );
};

export default CustomerManagementList;
