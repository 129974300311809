// Action Type
export const LOADER = 'LOADER'

const setLoader = (loading) => {
    return {
        type: LOADER,
        payload: loading
    }
}


export const loadingActions = {
    setLoader,
};
