import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { rentCancelService } from 'service';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const dispatch = useDispatch();
  const { className, filter, ...rest } = props;

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    dispatch(loadingActions.setLoader(true));
    rentCancelService
      .getAll(
        page * rowsPerPage,
        rowsPerPage,
        { field: 'postDate', dir: 'DESC' },
        filter
      )
      .then(
        response => {
          setListData(response.data);

          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  }, [rowsPerPage, page, filter, dispatch]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {listData.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(listData.length / rowsPerPage)}
      </Typography>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Түрээслэх хүсэлт</TableCell>
                    <TableCell>Төлөв</TableCell>
                    <TableCell>Хүсэлт гаргасан</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listData.slice(0, rowsPerPage).map(data => (
                    <TableRow hover key={data.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={'/rentrequests/detail/' + data.rent?.id}>
                          {'#' + data.rent?.id}
                        </Link>
                      </TableCell>
                      <TableCell>{data?.status}</TableCell>
                      <TableCell>
                        {moment(data.postDate).format('DD MMM YYYY | hh:mm')}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={'/cancelrequests/detail/' + data.id}
                          variant="outlined">
                          Харах
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={listData.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listData: PropTypes.array.isRequired
};

Results.defaultProps = {
  listData: []
};

export default Results;
