import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, LatestProjects, TeamTasks } from './components';

import React from 'react';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const DashboardDefault = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Default Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={6} xl={6} xs={12}>
          <TeamTasks />
        </Grid>
        <Grid item lg={6} xl={6} xs={12}>
          <LatestProjects />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardDefault;
