import { sendRequest } from './middleware';

const getAll = async (skip, take, sort = [], filter = []) => {
  try {
    let config = {
      method: 'post',
      url: '/api/admin/users',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        skip: skip,
        take: take,
        sortData: sort,
        filterData: filter
      }
    };
    const resp = await sendRequest(config);
    if (resp?.success) {
      return resp;
    } else return [];
  } catch (e) {
    console.log(e);
  }
};

const userDetail = async id => {
  let config = {
    method: 'get',
    url: '/api/user/' + id,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};
const userEdit = async object => {
  let config = {
    method: 'put',
    url: '/api/user/adminEdit',
    headers: {
      'Content-Type': 'application/json'
    },
    data: object
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const userDelete = async userId => {
  let config = {
    method: 'delete',
    url: '/api/admin/users/' + userId,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const exportData = async () => {
  let config = {
    method: 'get',
    url: '/api/admin/excel/export/user',
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'blob' }
  };
  const resp = await sendRequest(config);
  return resp;
};

export const userService = {
  getAll,
  userDetail,
  userEdit,
  userDelete,
  exportData
};
