import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const OrderInfo = props => {
  const { order, statusChange, onSave, className, ...rest } = props;

  const permissions = JSON.parse(localStorage.getItem('permissions', []));

  const session = useSelector(state => state.session);
  const classes = useStyles();

  const options = [
    { value: 'NEW', name: 'Веб-д ороогүй' },
    { value: 'Active', name: 'Веб-д орсон' },
    { value: 'Rejected', name: 'Зөвшөөрөгдөөгүй' }
  ];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Ерөнхий" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Хэрэглэгч</TableCell>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={'/management/customers/' + order.user?.id}>
                  {order.user?.email}
                </Link>
                <div>{order.user?.verified}</div>
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>ID</TableCell>
              <TableCell>#{order.id}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Он сар</TableCell>
              <TableCell>
                {moment(order.postDate).format('DD/MM/YYYY HH:MM')}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Хэмжээ</TableCell>
              <TableCell>{order.plot}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Давхар</TableCell>
              <TableCell>{order.floor}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Үнэ</TableCell>
              <TableCell>₮{order.price}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Төрөл</TableCell>
              <TableCell>{order.category?.name}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Төлөв</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  name="option"
                  onChange={statusChange}
                  select
                  SelectProps={{ native: true }}
                  value={order?.status}
                  variant="outlined">
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      {(permissions.includes('POST_ACTIONS') ||
        permissions.includes('ALL')) && (
        <CardActions className={classes.actions}>
          <Button onClick={onSave}>
            <EditIcon className={classes.buttonIcon} />
            Хадгалах
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

OrderInfo.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderInfo;
