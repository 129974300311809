import { sendRequest } from './middleware';
const axios = require('axios');
const login = async (username, password) => {
  let data = JSON.stringify({
    username: username,
    password: password
  });

  let config = {
    method: 'post',
    url: '/api/auth/login',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  const resp = (await axios(config)).data;
  if (resp?.success) localStorage.setItem('user', JSON.stringify(resp?.data));
  return resp;
};

const getUserPermissions = async roleId => {
  let config = {
    method: 'get',
    url: `/api/permissions/getRolePermissions/${roleId}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.data) return resp.data.map(({ action }) => action);
  return [];
};

export const authService = {
  login,
  getUserPermissions
};
