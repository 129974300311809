import { sendRequest } from './middleware';



const getAll = async () => {

    let config = {
        method: 'get',
        url: '/api/cancelation/get',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const create = async (body) => {
    let config = {
        method: 'POST',
        url: '/api/cancelation/create',
        headers: {
            'Content-Type': 'application/json',
        },
        data: body
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return [];
};


const update = async (postObject) => {
    let config = {
        method: 'PUT',
        url: '/api/cancelation/update',
        headers: {
            'Content-Type': 'application/json',
        },
        data: postObject
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return false;
};

const deleteItem = async (id) => {
    let config = {
        method: 'DELETE',
        url: '/api/cancelation/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return true;
    else return false;
};

const detail = async (id) => {
    let config = {
        method: 'GET',
        url: '/api/cancelation/detail/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp)
        return resp;
    else return false;
};



export const cancelationService = {
    getAll,
    create,
    update,
    deleteItem,
    detail
};