import { Button, Card, CardContent, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import { Page } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useDispatch } from 'react-redux';
import PageDetail from './PageDetail';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  }
}));

const ListView = props => {
  const {
    className,
    service,
    createNewData,
    DetailData,
    deleteable = true,
    createable = true,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = useState({});
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(null);

  const getpage = () => {
    dispatch(loadingActions.setLoader(true));
    service.getAll().then(
      r => {
        setItems(r);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  useEffect(() => {
    getpage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelected({});
  }, [dispatch]);

  const onSelect = config => {
    setSelected(config);
  };

  const onSave = data => {
    dispatch(loadingActions.setLoader(true));
    service.update(data).then(
      r => {
        getpage();
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const createNew = () => {
    dispatch(loadingActions.setLoader(true));
    service.create(createNewData).then(
      r => {
        getpage();
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const removeItem = () => {
    if (selected) {
      dispatch(loadingActions.setLoader(true));
      service.deleteItem(selected.id).then(
        r => {
          setSelected({});
          getpage();
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
    }
  };

  return (
    <Page className={classes.root} title="Settings">
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <Card>
            <CardContent className={classes.content}>
              <div
                style={{
                  marginBottom: '10px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px'
                }}>
                {createable && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={createNew}>
                    Нэмэх
                  </Button>
                )}
                {deleteable && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={removeItem}>
                    Устгах
                  </Button>
                )}
              </div>
              <ListGroup style={{ width: '100%' }}>
                {items?.map((item, idx) => {
                  return (
                    <ListGroup.Item
                      key={idx}
                      onClick={r => {
                        setActive(idx);
                        onSelect(item);
                      }}
                      active={active === idx ? true : false}>
                      {item.name}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          <PageDetail
            selected={selected}
            DetailData={DetailData}
            onSave={onSave}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired
};

export default ListView;
