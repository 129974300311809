/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { promotionService } from 'service/promotion.service';
import { textPageService } from 'service/textPages.service';
import ListView from 'views/ListView';
import RentCancelDetail from 'views/RentCancelDetail';
import RentCancelList from 'views/RentCancelList';
import RentRequestDetail from 'views/RentRequestDetail';
import RentRequestList from 'views/RentRequestList';
import Terms from 'views/Terms';
import AuthGuard from './components/AuthGuard';
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import Cancelation from './views/Cancelation';
import Categories from './views/Categories';
import ContactRequestList from './views/ContactRequestList';
import Contents from './views/Contents';
import CustomerManagementDetails from './views/CustomerManagementDetails';
import CustomerManagementList from './views/CustomerManagementList';
import DashboardDefaultView from './views/DashboardDefault';
import EmailTemplate from './views/EmailTemplate';
import OrderManagementDetails from './views/OrderManagementDetails';
import OrderManagementList from './views/OrderManagementList';
import Pages from './views/Pages';
import Settings from './views/Settings';
import VerificationList from './views/VerificationList';
import VerificationDetails from './views/VerificationListDetails';
import BlogList from './views/Blog/List';
import CreateBlog from 'views/Blog/Create';
import EditBlog from 'views/Blog/Edit';
import Logs from 'views/Log/List';
import RolesList from 'views/RolesManagement/List/List';
import RoleDetail from 'views/RolesManagement/Detail/Detail';
import AdminEmailTemplate from 'views/AdminEmailTemplate';
import TransactionLogs from 'views/TransactionLog/List';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => (
      <AuthGuard children={<Redirect to="/dashboards/default" />} />
    )
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboards/default',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <DashboardDefaultView
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
            />
          );
        }
      },
      {
        path: '/roles',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RolesList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'ALL'}
            />
          );
        }
      },
      {
        path: '/roles/detail/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RoleDetail
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'ALL'}
            />
          );
        }
      },
      {
        path: '/roles/detail/:id/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RoleDetail
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'ALL'}
            />
          );
        }
      },
      {
        path: '/management/customers',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <CustomerManagementList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'USER_LIST'}
            />
          );
        }
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <CustomerManagementDetails
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'USER_DETAIL'}
            />
          );
        }
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <CustomerManagementDetails
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'USER_DETAIL'}
            />
          );
        }
      },
      {
        path: '/management/orders',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <OrderManagementList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'POST_LIST'}
            />
          );
        }
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <OrderManagementDetails
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'POST_DETAIL'}
            />
          );
        }
      },
      {
        path: '/blog/list',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <BlogList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'BLOG_LIST'}
            />
          );
        }
      },
      {
        path: '/blog/create',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <CreateBlog
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'BLOG_INSERT'}
            />
          );
        }
      },
      {
        path: '/log',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Logs
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'LOG_LIST'}
            />
          );
        }
      },
      {
        path: '/transactions',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <TransactionLogs
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'LOG_LIST'}
            />
          );
        }
      },
      {
        path: '/blog/edit/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <EditBlog
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'BLOG_ACTIONS'}
            />
          );
        }
      },
      {
        path: '/rentrequests/detail/:id/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RentRequestDetail
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'RENT_REQUEST_DETAIL'}
            />
          );
        }
      },
      {
        path: '/rentrequests/detail/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RentRequestDetail
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'RENT_REQUEST_DETAIL'}
            />
          );
        }
      },
      {
        path: '/rentrequests/list',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RentRequestList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'RENT_REQUEST_LIST'}
            />
          );
        }
      },
      {
        path: '/cancelrequests/detail/:id/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RentCancelDetail
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'CANCELATION_DETAIL'}
            />
          );
        }
      },
      {
        path: '/cancelrequests/detail/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RentCancelDetail
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'CANCELATION_DETAIL'}
            />
          );
        }
      },
      {
        path: '/cancelrequests/list',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <RentCancelList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'CANCELATION_LIST'}
            />
          );
        }
      },
      {
        path: '/verifications',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <VerificationList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'VERIF_LIST'}
            />
          );
        }
      },
      {
        path: '/verifications/:id',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <VerificationDetails
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'VERIF_DETAIL'}
            />
          );
        }
      },
      {
        path: '/contactRequest',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <ContactRequestList
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'CONTACT_LIST'}
            />
          );
        }
      },
      {
        path: '/settings',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Settings
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/pages',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Pages
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/pages/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Pages
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/contents',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Contents
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/contents/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Contents
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/categories',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Categories
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/cancelation',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Cancelation
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/notiftemplate',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <EmailTemplate
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/adminemailtemplate',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <AdminEmailTemplate
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/terms',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Terms
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/terms/:tab',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <Terms
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/promotionType',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <ListView
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                  service={promotionService}
                  createNewData={{ name: 'new Promo', status: 'disabled' }}
                  DetailData={[
                    {
                      type: 'String',
                      name: 'Нэр',
                      field: 'name'
                    },
                    {
                      type: 'Radio',
                      name: 'Төлөв',
                      field: 'status'
                    },
                    {
                      type: 'Number',
                      name: 'Хугацаа',
                      field: 'duration'
                    },
                    {
                      type: 'Number',
                      name: 'Үнэ',
                      field: 'price'
                    }
                  ]}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        path: '/textPages',
        exact: true,
        component: params => {
          return (
            <AuthGuard
              children={
                <ListView
                  history={params.history}
                  match={params.match}
                  route={params.route}
                  location={params.location}
                  service={textPageService}
                  deleteable={false}
                  createable={false}
                  createNewData={{ name: 'new Promo', status: 'disabled' }}
                  DetailData={[
                    {
                      type: 'String',
                      name: 'Нэр',
                      field: 'name',
                      disabled: true
                    },
                    {
                      type: 'Quill',
                      name: 'Текст',
                      field: 'value'
                    }
                  ]}
                />
              }
              permission={'PARAMS_LIST'}
            />
          );
        }
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
