import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Page } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { configService } from '../../../../service';
import ImageGallery from 'react-image-gallery';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(0)
  },
  root1: {
    marginTop: theme.spacing(3)
  },
  content: {
    padding: theme.spacing(0, 3, 0, 3)
  },
  inner: {
    minWidth: 700,
    height: '100%',
    marginTop: theme.spacing(3)
  },
  textarea: {
    resize: 'both'
  }
}));

const OrderItems = props => {
  const { order, className, descChange, addressChange, ...rest } = props;

  const classes = useStyles();

  const [cities, setCities] = useState([]);

  const [city, setCity] = useState('');

  const [district, setDistrict] = useState('');

  const [states, setStates] = useState('');

  const [address, setAddress] = useState('');
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (order.postAttachments) {
      const tempGallery = [];
      for (let img of order?.postAttachments) {
        tempGallery.push({
          original: 'https://tureeslii.mn/files/' + img.file,
          thumbnail: 'https://tureeslii.mn/files/' + img.fileThumb,
          renderItem: renderImage
        });
      }
      setGallery(tempGallery);
    }

    configService.configDetail('cities').then(r => {
      if (r?.value) {
        setCities(JSON.parse(r.value));
      }
    });

    setCity(order?.city);
    setDistrict(order?.district);
    setStates(order?.state);
    setAddress(order?.address);
  }, [order]);

  const renderImage = item => {
    return (
      <img
        className="image-gallery-image"
        src={item.original}
        style={{ width: '100%', height: '100%' }}
      />
    );
  };

  return (
    <Page className={classes.root} title="Зарын дэлгэрэнгүй">
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Бусад" />
        <Divider />
        <CardContent
          className={classes.content}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <div style={{ width: '100%', height: '100%' }}>
            {gallery && <ImageGallery items={gallery} />}
          </div>
        </CardContent>
      </Card>

      <Card {...rest} className={clsx(classes.root1, className)}>
        <CardHeader title="Нэмэлт мэдээлэл" />
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            {/* <TextField
              fullWidth
              label="Description"
              placeholder="Description"
              multiline
              variant="outlined"
              value={order?.description}
              inputProps={{ className: classes.textarea }}
              onChange={descChange}
            /> */}
            <Form.Label>Нэмэлт мэдээлэл</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={descChange}
              value={order?.description}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <h3>Өрөөний мэдээлэл</h3>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Унтлагын өрөө</TableCell>
                    <TableCell>{order.bedroom}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>00 өрөө</TableCell>
                    <TableCell>{order.bathroom}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Гал тогоо</TableCell>
                    <TableCell>{order.kitchen}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Зочны өрөө</TableCell>
                    <TableCell>{order.livingRoom}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className="col-6">
              <h3>Хангамж</h3>
              <Table>
                <TableBody>
                  <TableRow selected>
                    <TableCell>Халаалт</TableCell>
                    <TableCell>{order.heating}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Усан хангамж</TableCell>
                    <TableCell>{order.waterSupply}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>00 Байршил</TableCell>
                    <TableCell>{order.restroom}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>

          <div className="row" style={{ paddingTop: 20 }}>
            <div className="col-6">
              <h3>Ерөнхий мэдээлэл</h3>
              <Table>
                <TableBody>
                  <TableRow selected>
                    <TableCell>Лифт</TableCell>
                    <TableCell>{order.elevator ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Тагт</TableCell>
                    <TableCell>{order.balcony ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Угаалгын машин</TableCell>
                    <TableCell>{order.washingMachine ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Зуух</TableCell>
                    <TableCell>{order.stove ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>WiFi</TableCell>
                    <TableCell>{order.wifi ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TV Кабел</TableCell>
                    <TableCell>{order.tvCable ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className="col-6">
              <h3>Хоиглох зүйлс</h3>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Тэжээмэл амьтан</TableCell>
                    <TableCell>{order.petAllowed ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Тамхи татах</TableCell>
                    <TableCell>{order.smokingAllowed ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Зочин</TableCell>
                    <TableCell>{order.guestAllowed ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Түрээслүүлэгч цуг байх</TableCell>
                    <TableCell>{order.livingProperty ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Түрээслүүлэх хүний тоо</TableCell>
                    <TableCell>{order.acceptedTenants}</TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Түрээслүүлэх хүний хүйс</TableCell>
                    <TableCell>{order.acceptedGender}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card {...rest} className={clsx(classes.root1, className)}>
        <CardHeader title="Байршил" />
        <Divider />
        <CardContent>
          <div className="row">
            <MapContainer center={[order?.lat, order?.long]} zoom={15}>
              <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />

              {order?.lat && order?.long && (
                <Marker position={{ lat: order.lat, lng: order.long }}></Marker>
              )}
              {/* <MapConsumer>
                {(map) => {
                  map.on("click", function (e) {
                    // setCurrentPos(e.latlng);
                  });
                  return null;
                }}
              </MapConsumer> */}
            </MapContainer>
          </div>
          <div className="row">
            <div className="col-6">
              <Form.Label>Хот</Form.Label>
              <Form.Select
                value={city}
                onChange={event => {
                  addressChange(event.target.value, states, district, address);
                  setCity(event.target.value);
                }}>
                {cities?.map((value, idx) => {
                  return (
                    <option key={idx} value={value.name}>
                      {value.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className="col-6">
              <Form.Label>Дүүрэг</Form.Label>
              <Form.Control
                type="text"
                value={states}
                onChange={event => {
                  addressChange(city, event.target.value, district, address);
                  setStates(event.target.value);
                }}
              />
            </div>
            <div className="col-6">
              <Form.Label>Гудамж</Form.Label>
              <Form.Control
                type="text"
                value={district}
                onChange={event => {
                  addressChange(city, states, event.target.value, address);
                  setDistrict(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Form.Label>Дэлгэрэнгүй хаяг</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={event => {
                  addressChange(city, states, district, event.target.value);
                  setAddress(event.target.value);
                }}
                value={address}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </Page>
  );
};

OrderItems.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderItems;
