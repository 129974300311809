import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { GenericMoreButton, Label } from 'components';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { userService } from '../../../../service';
import React, { useEffect, useState } from 'react';
import { loadingActions } from 'actions';
import { useDispatch } from 'react-redux';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 400
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const TeamTasks = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const permissions = JSON.parse(localStorage.getItem('permissions', []));
  const [customers, setCustomers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomers = () => {
      if (
        permissions &&
        (permissions.includes('ALL') || permissions.includes('USER_LIST'))
      )
        dispatch(loadingActions.setLoader(true));
      userService.getAll(0, 5, { field: 'id', dir: 'DESC' }).then(
        r => {
          setCustomers(r.data);
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          setCustomers([]);
          dispatch(loadingActions.setLoader(false));
        }
      );
    };

    fetchCustomers();
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Сүүлд бүртгүүлсэн" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Овог нэр</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Утас</TableCell>
                  <TableCell>Төрөл</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map(customer => (
                  <TableRow hover key={customer.id}>
                    <TableCell>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                        {`${customer.firstname} ${customer.lastname}`}
                        {customer.verified ? (
                          <VerifiedUserIcon
                            style={{
                              color: colors.green[600],
                              marginLeft: 10
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                        {customer.email}
                        {customer.EmailVerified ? (
                          <CheckIcon
                            style={{
                              color: colors.green[600],
                              marginLeft: 10
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                        {customer.mobile}
                        {customer.mobile_verified ? (
                          <CheckIcon
                            style={{
                              color: colors.green[600],
                              marginLeft: 10
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {customer.isCreator ? 'Түрээслүүлэгч' : 'Түрээслэгч'}
                    </TableCell>
                    {(permissions.includes('USER_DETAIL') ||
                      permissions.includes('ALL')) && (
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={'/management/customers/' + customer.id}
                          variant="outlined">
                          Харах
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/management/customers"
          variant="text">
          Бүгд
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

TeamTasks.propTypes = {
  className: PropTypes.string
};

export default TeamTasks;
