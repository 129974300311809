import { colors, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  General, Header
} from './components';


const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Contents = props => {

  const { match, history } = props;
  const classes = useStyles();
  const { tab } = match.params;

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'features', label: 'Үйлчилгээний онцлог', figure: 1 },
    { value: 'landlords', label: 'Түрээслүүлэх', figure: 2 },
    { value: 'our_specialty', label: 'Үйлчилгээний Баталгаа', figure: 1 },
  ];

  if (!tab) {
    return <Redirect to="/contents/features" />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }


  return (
    <Page
      className={classes.root}
    >
      <Header />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <General group={tab} figure={tabs.find(t => t.value === tab).figure} />
      </div>
    </Page>
  );
};

Contents.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Contents;
