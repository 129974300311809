import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));
const OtherActions = props => {
  const { className, ...rest } = props;
  const customer = props.customer;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Хэрэглэгчийн нэмэлт мэдээлэл" />
      <Divider />
      <CardContent>
        <div className="row" style={{ paddingTop: 20 }}>
          <div className="col-6">
            <Table>
              <TableBody>
                <TableRow selected>
                  <TableCell>Төрсөн он сар</TableCell>
                  <TableCell>
                    <b>
                      {customer?.birthdate ? customer?.birthdate : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Хүйс</TableCell>
                  <TableCell>
                    <b>
                      {customer?.gender === 'Male'
                        ? 'Эрэгтэй'
                        : customer?.gender === 'Female'
                        ? 'Эмэгтэй'
                        : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Хэдэн хүн амьдрах вэ?</TableCell>
                  <TableCell>
                    <b>
                      {customer?.rentPersonCount
                        ? customer?.rentPersonCount
                        : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Та сурдаг уу, ажилладаг уу?</TableCell>
                  <TableCell>
                    <b>{customer?.job ? customer?.job : 'Оруулаагүй'}</b>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>
                    Та хаана сурдаг вэ?/Та хаана ажилладаг вэ?
                  </TableCell>
                  <TableCell>
                    <b>
                      {customer?.jobTitle ? customer?.jobTitle : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Та ямар мэргэжилээр сурдаг вэ?/Та юу хийдэг вэ?
                  </TableCell>
                  <TableCell>
                    <b>
                      {customer?.profession
                        ? customer?.profession
                        : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="col-6">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Түрээсээ хэрхэн төлөх вэ?/Таны сарын цалин хэд вэ?
                  </TableCell>
                  <TableCell>
                    <b>
                      {customer?.payType ? customer?.payType : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Төлөгчийн цалин/орлогын хэмжээ хэд вэ?</TableCell>
                  <TableCell>
                    <b>
                      {customer?.incomeAmount
                        ? customer?.incomeAmount
                        : 'Оруулаагүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Өөрийнхөө талаарх товч мэдээлэл</TableCell>
                  <TableCell>
                    <b>{customer?.description}</b>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>
                    Байрны захиалагатай холбоотой мэдээллийг мэйлээр авья
                  </TableCell>
                  <TableCell>
                    <b>
                      {customer?.order_notification
                        ? customer?.order_notification
                        : 'Сонгоогүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Бүтээгдхүүн болон сурталчилгааны мэдээллийг мэйлээр авья
                  </TableCell>
                  <TableCell>
                    <b>
                      {customer?.product_ads_notification
                        ? customer?.product_ads_notification
                        : 'Сонгоогүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Компаний нэр</TableCell>
                  <TableCell>
                    <b>
                      {customer?.companyName
                        ? customer?.companyName
                        : 'Байхгүй'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Компаний регистр</TableCell>
                  <TableCell>
                    <b>
                      {customer?.companyRegistry
                        ? customer?.companyRegistry
                        : 'Байхгүй'}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

OtherActions.propTypes = {
  className: PropTypes.string
};

export default OtherActions;
