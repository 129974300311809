import {
  Button,
  Card,
  CardActions,
  CardContent,
  colors,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const PageDetail = props => {
  const { selected, className, onSave, DetailData, ...rest } = props;

  const { register, handleSubmit, reset, watch, setValue } = useForm();

  const onSubmit = data => {
    onSave(data);
    return true;
  };

  const classes = useStyles();

  useEffect(() => {
    reset(selected);
  }, [reset, selected]);

  const onEditorStateChange = (name, editorState) => {
    setValue(name, editorState);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              {(DetailData ? DetailData : []).map((field, idx) => {
                if (field.type === 'Number') {
                  return (
                    <Form.Group key={idx} className="mb-3">
                      <Form.Label>{field.name}</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={field.name}
                        {...register(field.field)}
                      />
                    </Form.Group>
                  );
                } else if (field.type === 'String') {
                  return (
                    <Form.Group key={idx} className="mb-3">
                      <Form.Label>{field.name}</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={field.disabled ? field.disabled : false}
                        placeholder={field.name}
                        {...register(field.field)}
                      />
                    </Form.Group>
                  );
                } else if (field.type === 'Quill') {
                  return (
                    <Form.Group key={idx} className="mb-3">
                      <Form.Label>{field.name}</Form.Label>
                      <ReactQuill
                        style={{ height: '500px' }}
                        theme="snow"
                        value={watch(field.field, 'empty')}
                        onChange={e => {
                          onEditorStateChange(field.field, e);
                        }}
                      />
                    </Form.Group>
                  );
                } else if (field.type === 'Radio') {
                  return (
                    <>
                      <Form.Group key={idx} className="mb-3">
                        <Form.Label>{field.name}</Form.Label>
                        <Form.Check
                          {...register(field.field)}
                          type="radio"
                          name={field.field}
                          value="ACTIVE"
                          id="active-radio"
                          label="Идэвхитэй"
                        />
                        <Form.Check
                          {...register(field.field)}
                          type="radio"
                          name={field.field}
                          value="disabled"
                          id="disabled-radio"
                          label="Идэвхигүй"
                        />
                      </Form.Group>
                    </>
                  );
                }
                return <></>;
              })}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            // onClick={save}
          >
            Хадгалах
          </Button>
        </CardActions>
      </Form>
    </Card>
  );
};

PageDetail.propTypes = {
  className: PropTypes.string
};

export default PageDetail;
