import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  Select
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Page } from 'components';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useDispatch } from 'react-redux';
import { logService } from 'service';

import { loadingActions } from 'actions';
import CommandList from '../Log/commandList';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const formatPrice = price => {
  return new Intl.NumberFormat().format(Number(price).toFixed(2)) + '₮';
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  inner: {
    width: '100%'
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  }
}));

const today = new Date();

const TransactionLogs = props => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(startDate.getTime() + 86400000)
  );
  const [type, setType] = useState('ALL');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = () => {
    dispatch(loadingActions.setLoader(true));
    logService
      .getAllTransactions(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        type
      )
      .then(
        r => {
          setLogs(r);
          setTotal(
            r.reduce(function(a, b) {
              return a + b['price'];
            }, 0)
          );
          dispatch(loadingActions.setLoader(false));
        },
        err => {
          dispatch(loadingActions.setLoader(false));
        }
      );
  };

  return (
    <Page className={classes.root} title="Хэрэглэгчийн мэдээлэл">
      <Card>
        <CardHeader
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 20,
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <label>Эхлэх : </label>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                />
              </div>
              <label>Дуусах : </label>
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                />
              </div>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                style={{ minWidth: '150px' }}
                value={type}
                onChange={e => {
                  setType(e.target.value);
                }}
                input={<BootstrapInput />}>
                <MenuItem value="ALL">Бүгд</MenuItem>
                <MenuItem value="PROMO">Онцгой болгох</MenuItem>
                <MenuItem value="PAYMENT">Түрээслэх хүсэлт</MenuItem>
              </Select>
              <Button
                color="primary"
                onClick={fetchLogs}
                size="small"
                variant="outlined">
                Хайх
              </Button>
            </div>
          }
          action={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                marginTop: 8,
                marginRight: 8
              }}>
              <label>Нийт : {formatPrice(total)}</label>
            </div>
          }
        />
        <CardContent className={classes.content}>
          <PerfectScrollbar options={{ suppressScrollY: true }}>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>Төрөл</TableCell>
                    <TableCell>Хэрэглэгч</TableCell>
                    <TableCell>Зар</TableCell>
                    <TableCell>Үнэ</TableCell>
                    <TableCell>Он сар</TableCell>
                    <TableCell>Qpay нэхэмжлэл дугаар</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map(log => (
                    <TableRow key={log?.id}>
                      <TableCell>{log.id}</TableCell>
                      <TableCell>{log.type}</TableCell>
                      <TableCell>{log.user?.email}</TableCell>
                      <TableCell>{log.post?.id}</TableCell>
                      <TableCell>{formatPrice(log.price)}</TableCell>
                      <TableCell>
                        {moment(log?.created_at).format(
                          'YYYY/MM/DD | hh:mm:ss'
                        )}
                      </TableCell>
                      <TableCell>{log.qpayTransactionId}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </Page>
  );
};

export default TransactionLogs;
