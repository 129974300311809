import { sendRequest } from './middleware';



const getAll = async (skip, take, sort = [], filter = []) => {

    let config = {
        method: 'post',
        url: '/api/user/verificationRequestList',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            skip: skip,
            take: take,
            sort: sort,
            filter: filter
        }
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return resp;
    else return [];
};


const verificationDetails = async (id) => {
    let config = {
        method: 'get',
        url: '/api/user/verifications/' + id,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return resp;
    else return [];
};
const verificationEdit = async (object) => {
    let config = {
        method: 'put',
        url: '/api/user/verifications',
        headers: {
            'Content-Type': 'application/json',
        },
        data: object
    };
    const resp = await sendRequest(config);
    if (resp?.success)
        return resp;
    else return [];
};
export const verificationService = {
    getAll,
    verificationDetails,
    verificationEdit
};