/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import BarChartIcon from '@material-ui/icons/BarChart';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import BookIcon from '@material-ui/icons/Book';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import SubjectIcon from '@material-ui/icons/Subject';
import LockOpenIcon from '@material-ui/icons/LockOpen';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Нүүр',
        href: '/dashboards/default',
        icon: HomeIcon
      },
      {
        title: 'Удирдлага',
        href: '/management',
        icon: BarChartIcon,
        children: [
          {
            title: 'Хэрэглэгчид',
            href: '/management/customers',
            permission: 'USER_LIST'
          },
          {
            title: 'Баталгаажуулах хүсэлт',
            href: '/verifications',
            permission: 'VERIF_LIST'
          },
          {
            title: 'Холбогдох хүсэлт',
            href: '/contactRequest',
            permission: 'CONTACT_LIST'
          },
          {
            title: 'Зар',
            href: '/management/orders',
            permission: 'POST_LIST'
          },
          {
            title: 'Ангилал',
            href: '/categories',
            permission: 'CATEGORY_LIST'
          },
          {
            title: 'Түрээслэх хүсэлтүүд',
            href: '/rentrequests/list',
            permission: 'RENT_REQUEST_LIST'
          },
          {
            title: 'Түрээс цуцлах хүсэлтүүд',
            href: '/cancelrequests/list',
            permission: 'CANCELATION_LIST'
          }
        ]
      },
      {
        title: 'Тохиргоо',
        href: '/settings',
        icon: SettingsIcon,
        children: [
          // {
          //   title: 'Parameters',
          //   href: '/settings',
          //   permission: 'PARAMS_LIST'
          // },
          {
            title: 'Асуулт & Хариулт',
            href: '/pages',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Мэдээлэлийн хуудас',
            href: '/contents',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Буцаан олголтын дүрэм',
            href: '/cancelation',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Хэрэглэгчийн мэдэгдлийн текстүүд',
            href: '/notiftemplate',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Админы email мэдэгдэл',
            href: '/adminemailtemplate',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Онцгой болгох төрөл',
            href: '/promotionType',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Текст хуудсууд',
            href: '/textPages',
            permission: 'PARAMS_LIST'
          },
          {
            title: 'Үйлчилгээний нөхцөл',
            href: '/terms/terms',
            permission: 'PARAMS_LIST'
          }
        ]
      },
      {
        title: 'Мэдээлэл / Блог',
        href: '/blog',
        icon: BookIcon,
        children: [
          {
            title: 'Нэмэх',
            href: '/blog/create',
            icon: AddIcon,
            permission: 'BLOG_INSERT'
          },
          {
            title: 'Жагсаалт',
            href: '/blog/list',
            icon: ListIcon,
            permission: 'BLOG_LIST'
          }
        ]
      },
      {
        title: 'Лог / Үйлдлийн түүх',
        href: '/log',
        icon: SubjectIcon,
        permission: 'LOG_LIST'
      },
      {
        title: 'Админ эрхийн төрөл',
        href: '/roles',
        icon: LockOpenIcon,
        permission: 'ALL'
      },
      {
        title: 'Гүйлгээний түүх',
        href: '/transactions',
        icon: LockOpenIcon,
        permission: 'TRANSACTION_LOG'
      }
    ]
  }
];
