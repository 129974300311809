import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Button,
  Modal,
  colors,
  Typography,
  TextField,
  CardActions,
  TableHead
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import moment from 'moment';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { loadingActions } from 'actions';
import { permissionsService } from 'service/permissions.service';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  rootModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actionsModal: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const RolesList = props => {
  const dispatch = useDispatch();
  const { match, history } = props;

  const classes = useStyles();

  const [logs, setLogs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [roleName, setRoleName] = useState('');

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLogs = () => {
    dispatch(loadingActions.setLoader(true));
    permissionsService.getAllRoles().then(
      r => {
        setLogs(r);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const onCreateRole = () => {
    dispatch(loadingActions.setLoader(true));
    permissionsService.createRole(roleName).then(
      r => {
        fetchLogs();
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
    setOpenModal(false);
  };

  return (
    <Page className={classes.root} title="Хэрэглэгчийн мэдээлэл">
      <Modal onClose={onClose} open={openModal}>
        <Card className={clsx(classes.rootModal)}>
          <form>
            <CardContent>
              <Typography align="center" gutterBottom variant="h3"></Typography>
              <Grid className={classes.container} container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Нэр"
                    name="name"
                    value={roleName}
                    onChange={e => {
                      setRoleName(e.target.value);
                    }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.actionsModal}>
              <Button onClick={onClose} variant="contained">
                Close
              </Button>
              <Button onClick={onCreateRole} variant="contained">
                Save
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>
      <Card>
        <CardHeader
          title="Roles"
          action={
            <IconButton
              aria-label="settings"
              onClick={() => {
                setOpenModal(true);
              }}>
              <AddIcon />
            </IconButton>
          }
        />
        <CardContent className={classes.content}>
          <PerfectScrollbar options={{ suppressScrollY: true }}>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Нэр</TableCell>
                    <TableCell>Үүсгэсэн</TableCell>
                    <TableCell>Үйлдэл</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map(log => (
                    <TableRow key={log.id}>
                      <TableCell>{log.name}</TableCell>
                      <TableCell>
                        {moment(log.created_at).format('YYYY/MM/DD | hh:mm:ss')}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            history.push(`/roles/detail/${log.id}`);
                          }}
                          variant="contained">
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </Page>
  );
};

export default RolesList;
