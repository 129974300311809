import {
  Button, Card,
  CardContent, Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { loadingActions } from 'actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { pagesService } from 'service';
import { PageDetail } from './components';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
}));

const General = props => {
  const { className, group, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedConfig, setSelectedConfig] = useState({});
  const [configs, setConfigs] = useState([]);
  const [active, setActive] = useState(null);



  const getPages = () => {
    dispatch(loadingActions.setLoader(true))
    pagesService.getAll(group).then(r => {
      setConfigs(r)
      dispatch(loadingActions.setLoader(false))
    }, err => {
      dispatch(loadingActions.setLoader(false))
    })
  }

  useEffect(() => {
    const getpage = () => {
      dispatch(loadingActions.setLoader(true))
      pagesService.getAll(group).then(r => {
        setConfigs(r)
        dispatch(loadingActions.setLoader(false))
      }, err => {
        dispatch(loadingActions.setLoader(false))
      })
    };
    getpage();
    setSelectedConfig({});
  }, [group, dispatch]);

  const onConfigSelect = (config) => {
    setSelectedConfig(config)
  }

  const createNew = () => {
    dispatch(loadingActions.setLoader(true))
    pagesService.create({ title: "Шинэ хэсэг", group: group }).then(r => {
      getPages();
      toast("нэмэгдлээ")
      dispatch(loadingActions.setLoader(false))
    }, err => {
      dispatch(loadingActions.setLoader(false))
    })
  }

  const removeItem = () => {
    if (selectedConfig && selectedConfig.id) {
      dispatch(loadingActions.setLoader(true))
      pagesService.deleteItem(selectedConfig.id).then(r => {
        getPages();
        toast("Устгагдлаа");
        setSelectedConfig({});
        dispatch(loadingActions.setLoader(false))
      }, err => {
        dispatch(loadingActions.setLoader(false))
      })
    }
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardContent className={classes.content}>
            <div style={{ marginBottom: '10px', width: "100%", display: "flex", flexDirection: "row", gap: "10px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={createNew}
              >
                Нэмэх
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeItem}
              >
                Устгах
              </Button>
            </div>
            <ListGroup style={{ width: "100%" }}>
              {configs?.map((config, idx) => {
                return (
                  <ListGroup.Item key={group + idx}
                    onClick={(r) => {
                      setActive(idx);
                      onConfigSelect(config);
                    }}
                    active={active === idx ? true : false}
                  >
                    {config.title}
                  </ListGroup.Item>
                )
              })
              }
            </ListGroup>
          </CardContent>
        </Card >
      </Grid>
      <Grid
        item
        lg={8}
        md={6}
        xl={8}
        xs={12}
      >
        <PageDetail config={selectedConfig} refresh={getPages} />
      </Grid>
    </Grid>
  );
};

General.propTypes = {
  className: PropTypes.string
};

export default General;
