import * as actionTypes from 'actions';

const initialState = {
    isLoading: false
}
const loadingActionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADER: return {
            ...state,
            isLoading: action.payload
        }
        default: return state
    }
}


export default loadingActionReducer;