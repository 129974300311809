import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';
import { userActions } from '../../actions';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

const AuthGuard = props => {
  const isEmptyObject = obj => {
    return !Object.keys(obj).length;
  };
  const { permission, children } = props;

  const session = useSelector(state => state.session);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem('permissions', '[]'));
    if (!session.loggedIn || !session.user || isEmptyObject(session.user)) {
      router.history.push('/auth/login');
      return;
    }
    if (permissions == null || !permissions?.includes('ALL')) {
      if (permissions == null || !permissions.includes(permission)) {
        dispatch(userActions.logout());
        router.history.push('/auth/login');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string
};

AuthGuard.defaultProps = {
  permission: ''
};

export default AuthGuard;
