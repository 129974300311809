import { makeStyles } from '@material-ui/styles';
import { Page, SearchBar } from 'components';
import React, { useEffect, useState } from 'react';
import { Results } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const RentCancelList = () => {
  const classes = useStyles();

  const [filter, setFilter] = useState([]);

  useEffect(() => {}, []);

  const onFilter = val => {
    const filt = [];
    for (let fil in val) {
      if (val[fil]) filt.push({ field: fil, op: 'LIKE', qry: val[fil] });
    }
    setFilter(filt);
  };

  const onClear = () => {
    setFilter([]);
  };

  const filterObject = [
    {
      type: 'text',
      field: 'id',
      name: 'Дугаар'
    },
    {
      type: 'text',
      field: 'user.email',
      name: 'Түрээслэгч'
    },
    {
      type: 'select',
      field: 'status',
      name: 'Төлөв',
      value: [
        {
          text: 'Төлбөр төлөгдсөн',
          value: 'PAID'
        },
        {
          text: 'Зөвшөөрсөн',
          value: 'ACCEPTED'
        },
        {
          text: 'Хугацаа дууссан',
          value: 'EXPIRED'
        },
        {
          text: 'Татгалзсан',
          value: 'DECLINED'
        }
      ]
    }
  ];

  const handleSearch = searchValue => {
    if (searchValue) {
      const aa = filter;
      aa.push({ field: 'title', op: 'LIKE', qry: searchValue });
      setFilter(aa);
    } else setFilter([]);
  };

  return (
    <Page className={classes.root} title="Түрээслэх хүсэлт">
      <SearchBar
        onSearch={handleSearch}
        filterObject={filterObject}
        onFilter={onFilter}
        onClear={onClear}
      />

      <Results className={classes.results} filter={filter} />
    </Page>
  );
};

export default RentCancelList;
