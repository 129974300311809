import { sendRequest } from './middleware';

const getAll = async (skip, take, sort = [], filter = []) => {
  let config = {
    method: 'post',
    url: '/api/blog/paginate',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      skip: skip,
      take: take,
      sort: sort,
      filter: filter
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const detail = async id => {
  let config = {
    method: 'get',
    url: '/api/blog/detail/' + id,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const create = async object => {
  let config = {
    method: 'post',
    url: '/api/blog/create',
    headers: {
      'Content-Type': 'application/json'
    },
    data: object
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const edit = async object => {
  let config = {
    method: 'put',
    url: '/api/blog/edit',
    headers: {
      'Content-Type': 'application/json'
    },
    data: object
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};

const deleteItem = async userId => {
  let config = {
    method: 'delete',
    url: '/api/blog/delete/' + userId,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const resp = await sendRequest(config);
  if (resp?.success) return resp;
  else return [];
};
export const blogService = {
  getAll,
  detail,
  edit,
  create,
  deleteItem
};
