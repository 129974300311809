import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ConfigList, GeneralSettings } from './components';


const useStyles = makeStyles(() => ({
  root: {}
}));

const General = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [selectedConfig, setSelectedConfig] = useState({});

  useEffect(() => {
  }, []);


  const onConfigSelect = (config) => {
    setSelectedConfig(config)
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
      >
        <ConfigList onConfigSelect={onConfigSelect} />
      </Grid>
      <Grid
        item
        lg={8}
        md={6}
        xl={8}
        xs={12}
      >
        <GeneralSettings config={selectedConfig} />
      </Grid>
    </Grid>
  );
};

General.propTypes = {
  className: PropTypes.string
};

export default General;
