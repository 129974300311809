import { colors, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import { Page } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Summary } from 'views/CustomerManagementDetails/components';
import OrderManagementDetails from 'views/OrderManagementDetails';
import { rentRequestService } from '../../service';
import { Request } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const RentRequestDetail = props => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const [request, setRequest] = useState(null);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  useEffect(() => {
    dispatch(loadingActions.setLoader(true));
    rentRequestService.detail(id).then(
      r => {
        dispatch(loadingActions.setLoader(false));
        if (r.success) {
          setRequest(r.data);
        } else {
          toast('Алдаа гарлаа');
        }
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  }, []);

  const tabs = [
    { value: 'request', label: 'Хүсэлт' },
    { value: 'user', label: 'Түрээслэгч' },
    { value: 'owner', label: 'Түрээслүүлэгч' },
    { value: 'post', label: 'Зар' }
  ];

  if (!tab) {
    return <Redirect to={`/rentrequests/detail/${id}/request`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    // return <Redirect to="/errors/error-404" />;
  }

  return (
    <Page className={classes.root} title="Хэрэглэгчийн мэдээлэл">
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'request' && request && (
          <Request request={request} history={history} />
        )}
        {tab === 'user' && request && (
          <Summary id={request.user.id} history={history} />
        )}
        {tab === 'owner' && request && (
          <Summary id={request.post.user.id} history={history} />
        )}
        {tab === 'post' && request && (
          <OrderManagementDetails postId={request.post.id} />
        )}
      </div>
    </Page>
  );
};

RentRequestDetail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default RentRequestDetail;
