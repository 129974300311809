import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logService } from 'service';

import { loadingActions } from 'actions';
import CommandList from 'views/Log/commandList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  inner: {
    width: '100%'
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  }
}));

const Logs = props => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [logs, setLogs] = useState([]);
  const [page] = useState(0);
  const id = props.id;
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    fetchLogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLogs = () => {
    dispatch(loadingActions.setLoader(true));
    logService.getAllByuser(id, page * rowsPerPage, rowsPerPage).then(
      r => {
        setLogs(r.data);
        dispatch(loadingActions.setLoader(false));
      },
      err => {
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  return (
    <Page className={classes.root} title="Хэрэглэгчийн мэдээлэл">
      <Card>
        <CardHeader title="Log" />
        <CardContent className={classes.content}>
          <PerfectScrollbar options={{ suppressScrollY: true }}>
            <div className={classes.inner}>
              <Table>
                <TableBody>
                  {logs.map(log => (
                    <TableRow key={log.id}>
                      <TableCell>{log.user.email}</TableCell>
                      <TableCell>{CommandList[log.command]}</TableCell>
                      <TableCell align="right">
                        {moment(log.created_at).format('YYYY/MM/DD | hh:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Logs;
