import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { loadingActions } from 'actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useQuill } from 'react-quilljs';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { emailTemplateService } from 'service';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const PageDetail = props => {
  const { config, className, ...rest } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [rep, setRep] = useState({});

  const handleSubmit = event => {
    event.preventDefault();

    const data = config;
    data.text = text;
    data.title = title;

    dispatch(loadingActions.setLoader(true));
    emailTemplateService.update(data).then(
      r => {
        dispatch(loadingActions.setLoader(false));
        toast('Хадгалагдсан');
      },
      err => {
        toast('Error');
        dispatch(loadingActions.setLoader(false));
      }
    );
  };

  useEffect(() => {
    if (config) {
      setTitle(config?.title);
      setText(config?.text);
    } else {
      setTitle('');
      setText('');
    }
    if (config && config.replacements && config.replacements.length > 0) {
      setRep(JSON.parse(config.replacements));
    } else {
      setRep('');
    }
  }, [config]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form onSubmit={handleSubmit}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <fieldset>
                {/* <Form.Group className="mb-3">
                  <Form.Label htmlFor="disabledTextInput">Нэр</Form.Label>
                  <Form.Control name="name" placeholder="Нэр" value={config?.name} defaultValue="" />
                </Form.Group> */}
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="disabledSelect">Гарчиг</Form.Label>
                  <Form.Control
                    name="title"
                    placeholder="Гарчиг"
                    value={title}
                    defaultValue=""
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                  />
                </Form.Group>
                <div className="row">
                  <b>Хувьсагчууд</b>
                  <ul>
                    {Object.entries(rep).map(([key, value]) => {
                      return (
                        <li key={key}>
                          {key} : <b>{value}</b>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="disabledSelect">Дэлгэрэнгүй</Form.Label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Тайлбар"
                    name="value"
                    rows={5}
                    value={text}
                    onChange={e => {
                      setText(e.target.value);
                    }}
                  />
                </Form.Group>
              </fieldset>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained">
            Хадгалах
          </Button>
        </CardActions>
      </Form>
    </Card>
  );
};

PageDetail.propTypes = {
  className: PropTypes.string
};

export default PageDetail;
